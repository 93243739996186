import React from "react";
import { GoPlus } from "react-icons/go";
import { HiMinusSmall } from "react-icons/hi2";
import { FormControl } from "react-bootstrap";
import PropTypes from "prop-types";

const IncDec = ({ minValue = 1, maxValue = 100, count, setCount }) => {
  const handleIncrementCounter = (e) => {
    e.preventDefault();
    if (count < maxValue) {
      setCount((prevState) => prevState + 1);
    }
  };

  const handleDecrementCounter = (e) => {
    e.preventDefault();
    if (count > minValue) {
      setCount((prevState) => prevState - 1);
    }
  };

  return (
    <div className="b-group ">
      <button
        className=" i-btn decrement-btn"
        style={{ borderRight: "1px solid #825846" }}
        onClick={(e) => {
          handleDecrementCounter(e);
        }}
      >
        <span className="material-symbols-outlined">
          <HiMinusSmall />
        </span>
      </button>
      <FormControl
        type="text"
        name="quantity"
        className="count text-center w-50"
        value={count}
        readOnly
      />
      <button
        className="i-btn increment-btn"
        style={{ borderLeft: "1px solid #825846" }}
        onClick={(e) => {
          handleIncrementCounter(e);
        }}
      >
        <span className="material-symbols-outlined">
          <GoPlus />
        </span>
      </button>
    </div>
  );
};
IncDec.propTypes = {
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  count: PropTypes.number.isRequired,
  setCount: PropTypes.func.isRequired,
};

export default IncDec;
