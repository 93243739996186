import React, { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Button, Image } from "react-bootstrap";
import Loader from "../Components//Loader";
import PageTitle from "../Components/PageTitle";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Contact() {
  const [page, setPage] = useState({});
  const [data, setData] = useState({});
  const [contactDetail, setContactDetail] = useState({});
  const [loding, setLoding] = useState(false);

  useEffect(() => {
    const slug = window.location.pathname.slice(1);
    setLoding(true)
    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => { });
    axios.get(BASE_URL + "footer").then((response) => {
      if (response?.data?.result === true) {
        setData(response?.data?.data);
      }
    });
    axios.get(BASE_URL + "contact").then((response) => {
      if (response?.data?.result === true) {
        setContactDetail(response?.data?.data?.contactQuote);
      }
    });
    setLoding(false)
  }, []);
  const logo = require('../assets/img/contact/choco-flow.png');

  return (
    <>
      {loding && <Loader />}
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <PageTitle pageData={page} />
      <Breadcrumb page="Contact" />
      <section className="contact">
        <div className="address">
          <p className="quote">We love hearing from <span>our customers</span> and those who share our passion for gooey brownie goodness!</p>
          <div className="mt-5">
            <div className="text-center">
              <p className="title">Business Owner </p>
              <p>S. Brindha Sivaprakasam</p>
            </div>
            <div className="mt-3">
              <p className="title">Business Address</p>
              <div className="text-center">
                <p>Plot no.82A, TNAU Nagar, Rajakambeeram, Thirumohur PO</p>
                <p>Y.Othakadai, Madurai  625107</p>
              </div>
            </div>
          </div>
        </div>
        <div className="image-container">
          <Image className="contact-image img-fluid" src={logo} alt="choco-flow-image" />
        </div>
        <div className="contact-div">
          <div className="contact-text">
            <div className="contact-quote" dangerouslySetInnerHTML={{ __html: contactDetail }} />
            <Button onClick={() => window.open(data?.whatsappUrl, '_blank')}>Get In Touch</Button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
