import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PageTitle from "../Components/PageTitle";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Chechout() {
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const minSelectableDate = new Date();
  minSelectableDate.setDate(minSelectableDate.getDate() + 2);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [err, setErr] = useState();
  const [agreed, setAgreed] = useState(false);
  const [data, setData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [page, setPage] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    const storedValue = localStorage.getItem("user_id");
    if (!storedValue) {
      navigate("/login");
    }
    const slug = window.location.pathname.slice(1);
    axios.get(BASE_URL + `seo/find-slug/${slug}`).then((res) => {
      if (res?.data?.result === true) {
        setPage(res.data.data);
      }
    });
    const storedProduct = JSON.parse(localStorage.getItem("myProduct")) || [];
    if (storedProduct.length < 1) {
      navigate("/cart");
    }
    axios
      .get(BASE_URL + `city`)
      .then((res) => {
        if (res?.data?.result === true) {
          setStateData(res.data.data);
        }
      })
      .catch(() => {});
  }, [navigate]);
  const getTotal = useCallback(() => {
    let alldata = [...data];
    const total = alldata.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.quantity * currentItem.price;
    }, 0);
    setGrandTotal(total);
    setTotalItem(alldata.length);
  }, [data, setGrandTotal, setTotalItem]);

  useEffect(() => {
    getTotal();
  }, [data, getTotal]);

  const handleAgreeChange = () => {
    setAgreed(!agreed);
  };
  const getData = () => {
    const storedProductJSON = localStorage.getItem("myProduct");
    const storedProduct = JSON.parse(storedProductJSON);
    setData(storedProduct);
  };
  const getNewProductData = (productData) => {
    return productData.map(
      ({
        images,
        description,
        categoryName,
        categoryId,
        createdAt,
        updatedAt,
        ...item
      }) => ({
        ...item,
        quantity: String(item.quantity),
        subTotal: String(item.price * item.quantity),
      })
    );
  };
  const prepareFormData = async (
    formdata,
    user_id,
    newproduct,
    agreed,
    selectedDate
  ) => {
    if (agreed) {
      formdata["grandTotal"] = grandTotal;
      formdata["customerId"] = user_id;
      formdata["products"] = newproduct;
      formdata["deliverOn"] = selectedDate;
    } else {
      const ordernote = formdata.orderNotes;
      try {
        const response = await axios.get(BASE_URL + `customer/${user_id}`);
        if (response?.data?.result === true) {
          const userData = response.data.data;
          if (
            !userData.address ||
            !userData.city ||
            !userData.doorNo ||
            !userData.landmark ||
            !userData.state ||
            !userData.zipCode
          ) {
            setTimeout(() => {
              toast.error("Please Enter Your Address To Buy Product", { theme: "colored" });
            }, 100);
            navigate("/profile/address");
            return null;
          } else {
            formdata = {
              ...formdata,
              orderNotes: ordernote,
              grandTotal: grandTotal,
              customerId: user_id,
              products: newproduct,
              deliverOn: selectedDate,
              name: userData.name,
              mobileNo: userData.mobileNo,
              email: userData.email,
              address: userData.address,
              city: userData.city,
              doorNo: userData.doorNo,
              landmark: userData.landmark,
              state: userData.state,
              zipCode: userData.zipCode,
            };
          }
        }
      } catch (error) {
        // Handle error
      }
    }

    return formdata;
  };
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  script.async = true;
  document.body.appendChild(script);

  const saveData = async (formdata) => {
    const user_id = localStorage.getItem("user_id");
    let product = [...data];

    const newproduct = getNewProductData(product);
    formdata = await prepareFormData(
      formdata,
      user_id,
      newproduct,
      agreed,
      selectedDate
    );

    if (formdata) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: formdata.grandTotal * 100,
        currency: "INR",
        name: formdata.name,
        description: "Test Payment",
        handler: function (response) {
          formdata["paymentResponse"] = response.razorpay_payment_id;

          axios.post(BASE_URL + "orders", formdata).then((response) => {
            if (response.data.result === true) {
              localStorage.removeItem("myProduct");
              navigate("/home");
              setTimeout(() => {
                toast.success(response.data.message, { theme: "colored" });
              }, 100);
            } else if (response.data.result === false) {
              setTimeout(() => {
                toast.error(response.data.message, { theme: "colored" });
              }, 100);
              setErr(response.data.errors);
            }
          });
        },
        prefill: {
          name: formdata.name,
          email: "yuvaganesh.saitech@gmail.com",
          contact: "",
        },
        notes: {
          address: "",
        },
        theme: {
          color: "#F37254",
        },
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    }
  };
  const handleStateChange = (e, field) => {
    let selectedValue = e.target.value;
    field.onChange(selectedValue);
    selectedValue = stateData?.find((item) => item.state === selectedValue);
    setCityData(selectedValue.city);
  };

  return (
    <>
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <PageTitle pageData={page} />
      <Breadcrumb page="Check Out" />
      <section className="section-ptb my-5 py-5">
        <Container>
          <Row>
            <Form onSubmit={handleSubmit(saveData)}>
              <Col>
                <div className="checkout-area">
                  <div className="billing-area p-3">
                    {agreed && (
                      <div className="billing-form">
                        <h2>Billing details</h2>
                        <Row className="billing-ul input-2">
                          <Col className="billing-li input">
                            <Form.Group className="log-email mt-3">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                style={{
                                  border: "1px solid rgba(130, 88, 70, 0.8)",
                                  borderRadius: "0px",
                                }}
                                type="text"
                                {...register("name", {
                                  required: "Name is required",
                                  pattern: {
                                    value: /^[A-Za-z]+$/,
                                    message:
                                      "Please enter a valid name with only alphabetical characters.",
                                  },
                                })}
                                placeholder="Enter Your Name"
                                name="name"
                                isInvalid={errors?.name}
                              ></Form.Control>
                              {errors?.name && (
                                <span className="text-danger">
                                  {errors?.name?.message}
                                </span>
                              )}
                              {err?.name && (
                                <span className="text-danger">{err?.name}</span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="billing-ul">
                          <Col className="billing-li">
                            <Form.Group className="log-email mt-3">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                style={{
                                  border: "1px solid rgba(130, 88, 70, 0.8)",
                                  borderRadius: "0px",
                                }}
                                type="email"
                                {...register("email", {
                                  required: "Email is required",
                                  pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Invalid email address",
                                  },
                                })}
                                placeholder="Enter Your Email"
                                isInvalid={errors?.email}
                              ></Form.Control>
                              {errors?.email && (
                                <span className="text-danger">
                                  {errors?.email?.message}
                                </span>
                              )}
                              {err?.email && (
                                <span className="text-danger">{err?.email}</span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="billing-ul">
                          <Col className="billing-li">
                            <Form.Group className="log-email mt-3">
                              <Form.Label>Mobile Number</Form.Label>
                              <Form.Control
                                style={{
                                  border: "1px solid rgba(130, 88, 70, 0.8)",
                                  borderRadius: "0px",
                                }}
                                placeholder="Enter Your Mobile Number"
                                type="text"
                                {...register("mobileNo", {
                                  required: "Mobile number is required",
                                  pattern: {
                                    value: /^\d+$/,
                                    message:
                                      "Mobile number must only contain numeric digits",
                                  },
                                  minLength: {
                                    value: 10,
                                    message:
                                      "Mobile number must be exactly 10 digits",
                                  },
                                  maxLength: {
                                    value: 10,
                                    message:
                                      "Mobile number must be exactly 10 digits",
                                  },
                                })}
                                name="mobileNo"
                                isInvalid={errors?.mobileNo}
                              ></Form.Control>
                              {errors?.mobileNo && (
                                <span className="text-danger">
                                  {errors?.mobileNo?.message}
                                </span>
                              )}
                              {err?.mobileNo && (
                                <span className="text-danger">
                                  {err?.mobileNo}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="billing-ul">
                          <Col className="billing-li">
                            <Form.Group className="log-email mt-3">
                              <Form.Label>
                                Apartment, Door no, unit etc.
                              </Form.Label>
                              <Form.Control
                                style={{
                                  border: "1px solid rgba(130, 88, 70, 0.8)",
                                  borderRadius: "0px",
                                }}
                                placeholder="Enter Apartment, Door no, unit etc."
                                type="text"
                                {...register("doorNo", {
                                  required: "Apartment, Door no is required",
                                })}
                                name="doorNo"
                                isInvalid={errors?.doorNo}
                              ></Form.Control>
                              {errors?.doorNo && (
                                <span className="text-danger">
                                  {errors?.doorNo?.message}
                                </span>
                              )}
                              {err?.doorNo && (
                                <span className="text-danger">
                                  {err?.doorNo}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="billing-ul">
                          <Col className="billing-li">
                            <Form.Group className="log-email mt-3">
                              <Form.Label>Address</Form.Label>
                              <Form.Control
                                style={{
                                  border: "1px solid rgba(130, 88, 70, 0.8)",
                                  borderRadius: "0px",
                                }}
                                type="text"
                                {...register("address", {
                                  required: "Address is required",
                                  pattern: {
                                    value: /^[a-zA-Z\s,.'-]+$/,
                                    message:
                                      "Address must only contain alphabetic characters ,coma and dot",
                                  },
                                })}
                                placeholder="Enter Your Address"
                                name="address"
                                isInvalid={errors?.address}
                              ></Form.Control>
                              {errors?.address && (
                                <span className="text-danger">
                                  {errors?.address?.message}
                                </span>
                              )}
                              {err?.address && (
                                <span className="text-danger">
                                  {err?.address}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="billing-ul">
                          <Col className="billing-li">
                            <Form.Group className="log-email mt-3">
                              <Form.Label>Landmark</Form.Label>
                              <Form.Control
                                style={{
                                  border: "1px solid rgba(130, 88, 70, 0.8)",
                                  borderRadius: "0px",
                                }}
                                placeholder="Enter your address landmark"
                                type="text"
                                {...register("landmark")}
                                name="landmark"
                                isInvalid={errors?.landmark}
                              ></Form.Control>
                              {errors?.landmark && (
                                <span className="text-danger">
                                  {errors?.landmark?.message}
                                </span>
                              )}
                              {err?.landmark && (
                                <span className="text-danger">
                                  {err?.landmark}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="billing-ul">
                          <Col className="billing-li">
                            <Form.Group className="mb-3 ">
                              <Form.Label>State</Form.Label>

                              <Controller
                                name="state"
                                control={control}
                                rules={{ required: "State is required" }}
                                defaultValue=""
                                render={({ field }) => (
                                  <Form.Select
                                    {...field}
                                    onChange={(e) =>
                                      handleStateChange(e, field)
                                    }
                                    style={{
                                      border:
                                        "1px solid rgba(130, 88, 70, 0.8)",
                                      borderRadius: "0px",
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select an option
                                    </option>
                                    {stateData?.map((item) => (
                                      <option key={item._id} value={item.state}>
                                        {item.state}
                                      </option>
                                    ))}
                                  </Form.Select>
                                )}
                              />

                              {errors.state && (
                                <span className="text-danger">
                                  {errors?.state?.message}
                                </span>
                              )}

                              {err?.state && (
                                <span className="text-danger">
                                  {err?.state}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="billing-ul">
                          <Col className="billing-li">
                            <Form.Group className="log-email mb-3">
                              <Form.Label>Town / City</Form.Label>
                              <Controller
                                name="city"
                                control={control}
                                style={{
                                  border: "1px solid rgba(130, 88, 70, 0.8)",
                                  borderRadius: "0px",
                                }}
                                rules={{
                                  validate: (value) => {
                                    if (value && value !== "Select an option") {
                                      return null;
                                    } else {
                                      return "City is required";
                                    }
                                  },
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <Form.Select
                                    {...field}
                                    style={{
                                      border:
                                        "1px solid rgba(130, 88, 70, 0.8)",
                                      borderRadius: "0px",
                                    }}
                                  >
                                    <option value={null}>
                                      Select an option
                                    </option>
                                    {cityData?.map((item, index) => (
                                      <option key={`ind${item}`} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </Form.Select>
                                )}
                              />
                              {errors?.city && (
                                <span className="text-danger">
                                  {errors?.city?.message}
                                </span>
                              )}
                              {err?.city && (
                                <span className="text-danger">{err?.city}</span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="billing-ul">
                          <Col className="billing-li">
                            <Form.Group className="log-email mb-4">
                              <Form.Label>Postcode / Zip</Form.Label>
                              <Form.Control
                                style={{
                                  border: "1px solid rgba(130, 88, 70, 0.8)",
                                  borderRadius: "0px",
                                }}
                                placeholder="Enter Postcode / Zip Code"
                                type="text"
                                {...register("zipCode", {
                                  required: "Postcode / Zip code is required",
                                  pattern: {
                                    value: /^\d+$/,
                                    message:
                                      "Postcode / Zip code must contain only numbers",
                                  },
                                })}
                                name="zipCode"
                                isInvalid={errors?.zipCode}
                              ></Form.Control>
                              {errors?.zipCode && (
                                <span className="text-danger">
                                  {errors?.zipCode?.message}
                                </span>
                              )}
                              {err?.zipCode && (
                                <span className="text-danger">
                                  {err?.zipCode}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    )}

                    <div className="billing-details">
                      <h2>Shipping details</h2>
                      <ul className="shipping-form pro-submit ps-0">
                        <li className="check-box label-info">
                          <Form.Group
                            controlId="shipToDifferentAddress"
                            className="log-pwd"
                          >
                            <Form.Check // prettier-ignore
                              type="checkbox"
                              label={
                                <p className="text-secondary ps-2 fs-6">
                                  {" "}
                                  Ship to a different address{" "}
                                </p>
                              }
                              checked={agreed}
                              onChange={handleAgreeChange}
                              className="custom-checkbox"
                            />
                          </Form.Group>
                        </li>
                        <li className="comment-area">
                          <Form.Group className="mb-3">
                            <Form.Label>Order notes (Optional)</Form.Label>
                            <Form.Control
                              as="textarea"
                              name="orderNotes"
                              style={{
                                border: "1px solid rgba(130, 88, 70, 0.8)",
                                borderRadius: "0px",
                              }}
                              {...register("orderNotes")}
                              isInvalid={errors?.orderNotes}
                              rows={5}
                              cols={80}
                            ></Form.Control>
                            {errors?.orderNotes && (
                              <span className="text-danger">
                                {errors?.orderNotes?.message}
                              </span>
                            )}
                            {err?.orderNotes && (
                              <span className="text-danger">
                                {err?.orderNotes}
                              </span>
                            )}
                          </Form.Group>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="order-area">
                    {/* Continue converting other elements */}
                    <div className="check-pro">
                      <h2>In your cart ({totalItem})</h2>
                      <ul className="check-ul">
                        {data?.map((item, index) => (
                          <li key={`ind${item._id}`}>
                            <div className="check-pro-img">
                              <Link to={`/product-detail/${item._id}`}>
                                <img
                                  src={`${BASE_URL}product/uploads/${item.coverImg}`}
                                  className="img-fluid"
                                  alt="p-1"
                                />
                              </Link>
                            </div>
                            <div className="check-content">
                              <Link to={`/product-detail/${item._id}`}>
                                {item.productName}
                              </Link>
                              <span className="check-code-blod">
                                <span>Product code:</span>
                                <span>{item.productCode}</span>
                              </span>
                              <div>
                                {item.pieces && (
                                  <span className="check-code-blod">
                                    Pieces:{item.pieces}
                                  </span>
                                )}
                                {item.size && (
                                  <span className="check-code-blod">
                                    Size:{item.size}
                                  </span>
                                )}
                              </div>
                              <span className="check-qty">
                                {item.quantity}X
                              </span>
                              <span className="check-price">₹{item.price}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Order details */}
                    <h2>Your order</h2>
                    <ul className="order-history">
                      <li className="order-details">
                        <span>Product:</span>
                        <span>Total</span>
                      </li>
                      {data?.map((item, index) => (
                        <li className="order-details" key={`ind${item._id}`}>
                          <span> {item.productName}</span>
                          <span>₹{item.quantity * item.price}</span>
                        </li>
                      ))}

                      <li className="order-details">
                        <span>Subtotal</span>
                        <span>₹{grandTotal}</span>
                      </li>
                      <li className="order-details">
                        <span>Shipping Charge</span>
                        <span>Free shipping</span>
                      </li>
                      <li className="order-details">
                        <span>Total</span>
                        <span>₹{grandTotal}</span>
                      </li>
                      <li className="order-details ">
                        <div className="text-start">
                          <Form.Group>
                            <Form.Label className="fw-bold text-start">
                              Deliver On
                            </Form.Label>
                            <InputGroup>
                              <DateTimePicker
                                onChange={(newDate) => setSelectedDate(newDate)}
                                value={selectedDate}
                                minDate={minSelectableDate}
                                format="dd-MM-y h:mm:ss a "
                                required
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </li>
                    </ul>

                    {/* Payment options */}
                    <div className="form-checkout-button">
                      <Button
                        className="account-btn-create fs-5 "
                        type="submit"
                      >
                        Place Order
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Form>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Chechout;
