import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function CustomizedThanks() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/home");
    }, 3000);
  }, [navigate]);
  return (
    <div className="thanks-container">
      <div className="thanks-message">Thanks for Customizing Your Order</div>
    </div>
  );
}

export default CustomizedThanks;
