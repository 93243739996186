import React,{useState} from 'react'
import Breadcrumb from '../Components/Breadcrumb'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom';
import Loader from "../Components/Loader";
import axios from "axios";
import { useForm} from "react-hook-form";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function ForgotPassword() {
  const [loding, setLoding] = useState(false);
    const [err, setErr] = useState();
  const navigate = useNavigate();

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({ mode: "all" });

    const forgotPass=(data)=>{
      setLoding(true)
      axios.post(BASE_URL+"customer/send-email",data)
      .then((res)=>{
       if (res?.data?.result === true) {
         setLoding(false)
         navigate('/login')
        }
       else if(res?.data?.result === false){
        setErr(res.data.errors);
         setLoding(false)
       }
      })
      .catch(()=>{
       setLoding(false)
     })
    }
  return (
    <>
    <Breadcrumb page="Login"/>
    <section className="customer-page section-ptb">
     <Container>
     {loding && <Loader />}

       <Row>
         <Col>
           <div className="acc-form">
             <div className="log-acc-page" id="CustomerLoginForm">
               <div className="content-main-title">
                 <div className="section-cont-title text-center">
                   <h2>
                     <span>Reset  Password Verification</span>
                   </h2>
                   <p>We will send you an email to reset your password.</p>
                 </div>
               </div>
               <div className="acc-page">
                 <div className="login">
                   <Form onSubmit={handleSubmit(forgotPass)}>
                     <div className="login-form-container">
                     <Form.Group className="log-email mt-3">
                            <Form.Label>Email</Form.Label>

                            <Form.Control
                              style={{
                                border: "1px solid rgba(130, 88, 70, 0.8)",
                                borderRadius: "0px",
                              }}
                              type="email"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value: /^\S+@\S+$/,
                                  message: "Invalid email address",
                                },
                              })}
                              placeholder="Enter Your Email"
                              isInvalid={errors?.email}
                            ></Form.Control>
                            {errors?.email && (
                              <span className="text-danger">
                                {errors?.email?.message}
                              </span>
                            )}
                            {err?.email && (
                              <span className="text-danger">{err?.email}</span>
                            )}
                          </Form.Group>

                     

                       <div className="form-action-button mb-5">
                         <Button className="account-forgot-btn fs-5 text-uppercase "   type="submit">
                           Submit
                         </Button>
                         <Link to="/login"  className='text-muted w-auto fs-5 pb-2 border-0' >
                            Cancel
                          </Link>
                       </div>
                     </div>
                   </Form>
                 </div>
                 
               </div>
             </div>

            
           </div>
         </Col>
       </Row>
     </Container>
   </section>
   </>
  )
}

export default ForgotPassword