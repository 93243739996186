import React, { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Container, Image } from "react-bootstrap";

import PageTitle from "../Components/PageTitle";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
function Location() {
    const [page, setPage] = useState({});
    useEffect(() => {
      const slug = window.location.pathname.slice(1);
      axios
        .get(BASE_URL + `seo/find-slug/${slug}`)
        .then((res) => {
          if (res?.data?.result === true) {
            setPage(res?.data?.data);
          }
        })
        .catch(() => {});
    }, []);

    const logo = require('../assets/img/footer/footer-temple.png');

    return (
        <>
            <ScrollToTop
                smooth
                style={{ background: "#2b1b1b", borderRadius: "0px" }}
                component={<IoMdArrowRoundUp className="text-white" />}
            />
            <PageTitle pageData={page} />
            <Breadcrumb page="Location" />

            <div className="location-container">
                <Container className="location">
                    <p className="main-title">CRAFTED IN THE HEART OF</p>
                    <p className="city-name">Madurai city</p>
                    <p className="quote px-md-2">Indulge in the decadence of Gooey Brownie brand at your Madurai location, where our rich and gooey brownies redefine the chocolate perfection</p>
                    <p className="quote"></p>
                </Container>
                <Image src={logo} alt="Above Footer Image" className="above-footer-image h-full w-full" />
            </div>
        </>
    );
}

export default Location;
