import React, { useEffect, useState } from "react";
import {  Card,  Col,  Container,  Row,  Form,  Button,  InputGroup,} from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import { toast } from "react-toastify";
import PageTitle from "../../Components/PageTitle";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Suprise() {
  useEffect(() => {
    const slug = window.location.pathname.slice(1);

    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => {});
  }, []);
  const navigate = useNavigate();
  const [err, setErr] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [page, setPage] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const saveData = (data) => {
    const id = localStorage.getItem("user_id");

    if (!id) {
      setTimeout(() => {
        toast.error("Please Login to order Your Suprise Gift", { theme: "colored" });
      }, 100);
      navigate("/login");
    } else {
      data.supriseOn = selectedDate;
      data.customerId = id;

      axios.post(BASE_URL + `suprise`, data).then((res) => {
        if (res?.data?.result === true) {
          navigate("/suprise-thanks");
        } else if (res?.data?.result === false) {
          setErr(res.data.errors);
        }
      });
    }
  };
  const minSelectableDate = new Date();
  minSelectableDate.setDate(minSelectableDate.getDate() + 2);
  return (
    <>
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <section className="product-features pb-5 ">
        <PageTitle pageData={page} />
        <Container>
          <Card className="mb-5">
            <Card.Body className="my-5">
              <div className="fs-2 text-center fw-bold">Suprise Gift</div>
              <Form autoComplete="off" onSubmit={handleSubmit(saveData)}>
                <Row className="justify-content-center mt-4 ">
                  <Col lg={6}>
                    <Form.Group controlId="datepicker " className="mb-3">
                      <Form.Label className=" text-start ">
                        Suprise On
                      </Form.Label>
                      <InputGroup>
                        <DateTimePicker
                          onChange={(newDate) => setSelectedDate(newDate)}
                          value={selectedDate}
                          minDate={minSelectableDate}
                          format="dd-MM-y h:mm:ss a "
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-5">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        style={{
                          height: "180px",
                          border: "1px solid rgba(130, 88, 70, 0.8)",
                          borderRadius: "0px",
                        }}
                        {...register("description", {
                          required: "Description is required",
                        })}
                        isInvalid={errors?.description}
                      ></Form.Control>
                      {errors?.description && (
                        <span className="text-danger">
                          { errors?.description?.message}
                        </span>
                      )}
                      {err?.description && (
                        <span className="text-danger">{err?.description}</span>
                      )}
                    </Form.Group>
                    <div className="form-action-button mt-4 text-center">
                      <Button
                        className="account-btn fs-5 w-auto mb-4 rounded"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                  <Row>
                    <Col></Col>
                  </Row>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default Suprise;
