
import './App.css';
import Header from './Components/Header';
import NavBar from './Components/NavBar';
import './style.css'
import './assets/style/animation.css'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './Components/Footer';
import AboutUs from './pages/AboutUs';
import Products from './pages/Products';
import ProductDetail from './Components/product/ProductDetail';
import Headroom from 'react-headroom';
import MyCart from './pages/MyCart';
import Contact from './pages/Contact';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Profile from './Components/profile/Profile';
import Order from './Components/profile/Order';
import MyProfile from './Components/profile/MyProfile';
import Address from './Components/profile/Address';
import ChangePass from './Components/profile/ChangePass';
import OrderDetail from './Components/profile/OrderDetail';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Checkout from './pages/Checkout';
import Customize from './pages/customize-order/Customize';
import Suprise from './pages/suprise-gift/Suprise';
import CustomizedThanks from './pages/customize-order/CustomizedThanks';
import SupriseThanks from './pages/suprise-gift/SupriseThanks';
import Location from '../src/pages/Location';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import Pricing from './pages/Pricing';
import ShippingPolicy from './pages/ShippingPolicy';

function App() {
  return (
    <>
      <Headroom>
        <Header />
        <NavBar />
      </Headroom>
      <Routes>
        <Route index element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/products' element={<Products />} />
        <Route path='/product-detail/:id' element={<ProductDetail />} />
        <Route path='/cart' element={<MyCart />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/location' element={<Location />} />
        <Route path='/login' element={<Login />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password/:encemail' element={<ResetPassword />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/customize-your-order' element={<Customize />} />
        <Route path='/customize-thanks' element={<CustomizedThanks />} />
        <Route path='/suprise-gift' element={<Suprise />} />
        <Route path='/suprise-thanks' element={<SupriseThanks />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/shipping-policy' element={<ShippingPolicy />} />

        <Route path='/profile' element={<Profile />} >
          <Route path='order' element={<Order />} />
          <Route path='my-profile' element={<MyProfile />} />
          <Route path='address' element={<Address />} />
          <Route path='change-password' element={<ChangePass />} />
          <Route path='order-detail/:id' element={<OrderDetail />} />
        </Route>
        
      </Routes>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
