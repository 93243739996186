import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import Loader from "../Loader";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import PageTitle from "../PageTitle";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function MyProfile() {
  const [loding, setLoding] = useState(false);
  const [err, setErr] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const id = localStorage.getItem("user_id");
  const [page, setPage] = useState({});

  useEffect(() => {
    const storedValue = localStorage.getItem("user_id");

    if (storedValue) {
      setLoding(true);
      axios
        .get(BASE_URL + `customer/${id}`)
        .then((res) => {
          if (res?.data?.result === true) {
            setValue("name", res.data.data.name);
            setValue("mobileNo", res.data.data.mobileNo);
            setValue("email", res.data.data.email);
            setLoding(false);
          } else if (res?.data?.result === false) {
            setLoding(false);
          }
        })
        .catch(() => {
          setLoding(false);
        });
    }

    const path = window.location.pathname.slice(1);
    const parts = path.split("/");
    const slug = parts[1];

    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => {});
  }, [id, setValue]);
  const submit = (data) => {
    setLoding(true);
    setErr("");
    const form = new FormData();
    form.append("name", data.name);
    form.append("mobileNo", data.mobileNo);
    form.append("email", data.email);

    // form.append("profile", data.profile[0]);
    axios
      .put(BASE_URL + `customer/${id}`, form)
      .then((res) => {
        if (res?.data?.result === true) {
          setLoding(false);
          setTimeout(() => {
            toast.success(res.data.message, { theme: "colored" });
          }, 100);
          sessionStorage.setItem("myProfile", res?.data?.data?.profile);
        } else if (res?.data?.result === false) {
          setErr(res.data.errors);
          setLoding(false);
        }
      })
      .catch(() => {
        setLoding(false);
      });
  };

  return (
    <>
      <Container className="">
        <PageTitle pageData={page} />

        <Row>
          {loding && <Loader />}
          <Col lg={12}>
            <div className="profile-border">Profile</div>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(submit)}>
          <div className="login-form-container">
            <Row>
              <Col lg={6}>
                <Row>
                  <Col>
                    <Form.Group className="log-email mt-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        style={{
                          border: "1px solid rgba(130, 88, 70, 0.8)",
                          borderRadius: "0px",
                        }}
                        type="text"
                        {...register("name", {
                          required: "Name is required",
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message:
                              "Name must only contain alphabetic characters and spaces",
                          },
                        })}
                        placeholder="Enter Your Name"
                        name="name"
                        isInvalid={errors?.name}
                      ></Form.Control>
                      {errors?.name && (
                        <span className="text-danger">
                          {errors?.name?.message}
                        </span>
                      )}
                      {err?.name && (
                        <span className="text-danger">{err?.name}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="log-email mt-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        style={{
                          border: "1px solid rgba(130, 88, 70, 0.8)",
                          borderRadius: "0px",
                        }}
                        type="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                          },
                        })}
                        placeholder="Enter Your Email"
                        isInvalid={errors?.email}
                      ></Form.Control>
                      {errors?.email && (
                        <span className="text-danger">
                          {errors?.email?.message}
                        </span>
                      )}
                      {err?.email && (
                        <span className="text-danger">{err?.email}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="form-action-button">
                <Form.Group className="log-email mt-3">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    style={{
                      border: "1px solid rgba(130, 88, 70, 0.8)",
                      borderRadius: "0px",
                    }}
                    placeholder="Enter Your Mobile Number"
                    type="text"
                    {...register("mobileNo", {
                      required: "Mobile number is required",
                      pattern: {
                        value: /^\d+$/,
                        message:
                          "Mobile number must only contain numeric digits",
                      },
                      minLength: {
                        value: 10,
                        message: "Mobile number must be exactly 10 digits",
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be exactly 10 digits",
                      },
                    })}
                    name="mobileNo"
                    isInvalid={errors?.mobileNo}
                  ></Form.Control>
                  {errors?.mobileNo && (
                    <span className="text-danger">
                      {errors?.mobileNo?.message}
                    </span>
                  )}
                  {err?.mobileNo && (
                    <span className="text-danger">{err?.mobileNo}</span>
                  )}
                </Form.Group>

                {/* <Form.Group className="mt-3">
                  <Form.Label>Profile</Form.Label>
                  <Form.Control
                    type="file"
                    {...register("profile")}
                    name="profile"
                    style={{
                      border: "1px solid rgba(130, 88, 70, 0.8)",
                      borderRadius: "0px",
                    }}
                    isInvalid={errors?.profile}
                  />

                  {errors?.profile && (
                    <span className="text-danger">
                      {errors?.profile?.message}
                    </span>
                  )}
                  {err?.profile && (
                    <span className="text-danger">{err?.profile}</span>
                  )}
                </Form.Group> */}
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-action-button mt-4">
                  <Button
                    className="account-btn fs-5 w-auto mb-4"
                    type="submit"
                  >
                    Update Profile
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
}

export default MyProfile;
