import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function OrderDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get(BASE_URL + `orders/customer-product/${id}`).then((res) => {
      if (res?.data?.result === true) {
        setData(res.data.data);
      }
    });
  }, [id]);

  return (
    <Container className="">
      <Row>
        <Col lg={12}>
          <div className="profile-border">Order Details</div>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <div className="cart-page-wrap">
            <div className="cart-wrap-info">
              <div className="cart-item-wrap mt-2">
                <div className="cart-title d-flex justify-content-between profile-border">
                  <h6>My Order Items:</h6>
                  <span className="cart-count">
                    <span className="cart-counter me-2">
                      {data?.products?.length}
                    </span>
                    <span className="cart-item-title"> Item</span>
                  </span>
                </div>
                <div className="profile-border ">
                  {data?.products?.map((item) => (
                    <ul
                      className="ps-0 d-flex justify-content-between"
                      key={item._id}
                    >
                      <li className="item-info mb-4 d-flex align-items-center">
                        <div className="item-img me-2 ">
                          <Link to="#" className="">
                            <img
                              src={`${BASE_URL}product/uploads/${item.coverImg}`}
                              className="img-fluid  h-50"
                              alt="p-1"
                            />
                          </Link>
                        </div>
                        <div className="item-title mt-3">
                          <Link
                            to="#"
                            className="text-decoration-none text-black fw-bold"
                          >
                            {item.productName}
                          </Link>
                          {item.pieces && (
                            <div className="item-option me-2">
                              <span className="pro-variant-title">Pieces:</span>
                              <span className="pro-variant-type">
                                {item.pieces}
                              </span>
                            </div>
                          )}
                          {item.size && (
                            <div className="item-option me-2">
                              <span className="pro-variant-title">Size:</span>
                              <span className="pro-variant-type">
                                {item.size}
                              </span>
                            </div>
                          )}
                          <div className="item-option">
                            <span className="item-price">₹{item.price} </span>
                          </div>
                        </div>
                        <div className="me-auto "></div>
                      </li>
                      <li className="item-qty  d-flex align-items-center">
                        <div className="product-quantity-action">
                          <div className="product-quantity">
                            <div className="cart-plus-minus"></div>
                          </div>
                        </div>
                        <div className="item-remove text-center">
                          <div className="remove-wrap">
                            Quantity:{item.quantity}
                          </div>
                        </div>
                      </li>

                      <li className="item-price  d-flex align-items-center">
                        <span className="amount full-price">
                          ₹{item.subTotal}
                        </span>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default OrderDetail;
