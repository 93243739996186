import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function SupriseThanks() {
    const navigate=useNavigate();
    useEffect(()=>{
        setTimeout(() => {
            navigate("/home")
          }, 3000);
        
    },[navigate])
  return (
    <div className="thanks-container">
    <div className="thanks-message">Thanks for Ordering Suprise Gift</div>
  </div>
  )
}

export default SupriseThanks