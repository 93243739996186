import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';  
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function OurService({ data }) {
  const services = [
    {
      id:"1",
      img: data.icoImg1,
      title: data.title1,
      desc: data.description1,
    },
    {
      id:"2",
      img: data.icoImg2,
      title: data.title2,
      desc: data.description2,
    },
    {
      id:"3",
      img: data.icoImg3,
      title: data.title3,
      desc: data.description3,
    },
    {
      id:"4",
      img: data.icoImg4,
      title: data.title4,
      desc: data.description4,
    },
  ];

  return (
    <section className="our-service-area py-5">
      <Container >
        <Row>
          <Col className="cat-col align-items-center">
            <div className="single-service">
              {services.map((item, index) => (
                <div className="service-content" key={item.id}>
                  <div className="ser-block">
                    <Link to="#">
                      <img
                        src={`${BASE_URL}home/uploads/${item.img}`}
                        className="img-fluid"
                        alt={`service-${index + 1}`}
                      />
                    </Link>
                    <div className="service-text">
                      <h6 className="text-uppercase">{item.title}</h6>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

OurService.propTypes = {
  data: PropTypes.shape({
    icoImg1: PropTypes.string,
    title1: PropTypes.string,
    description1: PropTypes.string,
    icoImg2: PropTypes.string,
    title2: PropTypes.string,
    description2: PropTypes.string,
    icoImg3: PropTypes.string,
    title3: PropTypes.string,
    description3: PropTypes.string,
    icoImg4: PropTypes.string,
    title4: PropTypes.string,
    description4: PropTypes.string,
  }).isRequired,
};

export default OurService;
