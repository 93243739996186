import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import axios from 'axios';
import WhatsAppButton from './whatsapp-button/WhatsAppButton';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const Footer = () => {
  const [data, setData] = useState({});
  const [category, setCategory] = useState([]);

  const getCategory = useCallback(async () => {
    const response = await axios.get(BASE_URL + "category");
    setCategory(response?.data?.data)
  }, [setCategory])

  useEffect(() => {
    axios
      .get(BASE_URL + "footer")
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        }
      });
    getCategory()
  }, [getCategory])

  const footerImg = require('../assets/img/footer/gooey_logo_mark.png');
  return (
    <footer>
      <WhatsAppButton />
      <div className="footer-top-area">
        <Container>

          <Row className='row-cols-1 row-cols-md-2 row-cols-lg-4 g-4'>
            <Col>
              <div>
                <h2 className="footer-title">Quick Links</h2>
                <p>
                  <Link to='/pricing' className="sub-link text-decoration-none mt-2">Pricing</Link> <br/>
                  <Link to='/location' className="sub-link text-decoration-none mt-2">Location</Link> <br />
                  <Link to='/contact' className="sub-link text-decoration-none mt-2">Contact Us</Link><br/>
                  <Link to='/terms-and-conditions' className="sub-link text-decoration-none mt-2">Terms and Conditions</Link><br/>
                  <Link to='/privacy-policy' className="sub-link text-decoration-none mt-2">Privacy Policy</Link> <br/>
                  <Link to='/refund-policy' className="sub-link text-decoration-none mt-2">Refund Policy</Link><br/>
                  <Link to='/shipping-policy' className="sub-link text-decoration-none mt-2">Shipping Policy</Link>
                </p>
              </div>
            </Col>
            <Col>
              <div>
                <h2 className="footer-title">CATEGORIES</h2>
                <ul className='p-0'>
                  {category?.map((cat) => (
                    <li key={cat?._id}>
                      <Link className='text-decoration-none sub-link mt-2'>{cat?.categoryName}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col>
              <div>
                <h2 className="footer-title">{data?.discription}</h2>
                <p><a href={`tel:${data?.mobileNo}`} rel="noopener noreferrer" className='mt-2 social'>
                Call: {data.mobileNo}
                </a>
                </p>
                <p className='mt-2'>{`${data?.timing} IST`}</p>
                <p><a href={`mailto:${data?.email}`} target="_blank" rel="noopener noreferrer" className='mt-2 social'>
                  {data?.email}
                </a>
                </p>
              </div>
            </Col>
            <Col>
              <div className="footer-img">
                <Image src={footerImg} fluid alt="logo" />
              </div>
            </Col>
          </Row>
          <p className="footer-text d-flex justify-content-md-end">Kiss the Bliss</p>
        </Container>
      </div>
      <div className="footer-bottom-area pb-0 ">
        <Container>
          <Row>
            <Col>
              <ul className="ft-bottom">
                <li className="social-icon">
                  <ul className="social-icon ps-0">
                    <li>
                      <Link to={data.facebookUrl} target="_blank" rel="noopener noreferrer">
                        <span className="icon-social"><FontAwesomeIcon icon={faFacebook} className="text-white fs-4" /></span>
                      </Link>
                    </li>
                    <li>
                      <Link to={data.instagramUrl} target="_blank" rel="noopener noreferrer">
                        <span className="icon-social"><FontAwesomeIcon icon={faInstagram} className="text-white fs-4" /></span>
                      </Link>
                    </li>
                    <li>
                      <Link to={data.whatsappUrl} target="_blank" rel="noopener noreferrer">
                        <span className="icon-social"><FontAwesomeIcon className="text-white fs-4" icon={faWhatsapp} /></span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="copy-right">
                  <p>
                    <span className="copy-icon fs-5">&copy; </span>
                    <span> Gooey Brownies All Rights Reserved</span>
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
