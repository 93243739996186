import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import ProductSlice from './slice/ProductCountSlice';
import {  HelmetProvider } from 'react-helmet-async';

const store = configureStore({
  reducer: {
    product: ProductSlice,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
    <HelmetProvider>
    <Provider store={store}>
    <App />
  </Provider>
  </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
