import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Testimonial() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(BASE_URL + `testimonial`)
      .then((response) => {
        if (response?.data?.result === true) {
          setData(response.data.data);
        }
      })
      .catch(() => { });
  }, []);
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 500,
    autoplay: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="testimonial-area py-5">
      <Container className="pb-5">
        <Row>
          <div className="section-title py-4">
            <span className="sub-title">POSITIVE CLIENT REVIEWS</span>
            <h2><span>Our </span> happy client
            </h2>
          </div>

          <Col className=" align-items-center">
            <div
              className="testi-slider owl-carousel owl-theme"
              id="testimonial-slider"
            >
              {data.length === 1 && (
                <div className="item pb-5">
                  <div className="testi-content">
                    <div className="testi-review-time">
                      {data[0].rating === "1" && (
                        <span className="testi-review">
                          <FaStar style={{ color: "#fe9704" }} />
                        </span>
                      )}
                      {data[0].rating === "2" && (
                        <span className="testi-review">
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                        </span>
                      )}
                      {data[0].rating === "3" && (
                        <span className="testi-review">
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                        </span>
                      )}
                      {data[0].rating === "4" && (
                        <span className="testi-review">
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                        </span>
                      )}
                      {data[0].rating === "5" && (
                        <span className="testi-review">
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                          <FaStar style={{ color: "#fe9704" }} />
                        </span>
                      )}
                    </div>

                    <div className="testi-desc">
                      <p>{data[0].feedback}</p>
                    </div>

                    <div className="testi-avtar">
                      <div className="avtar-img">
                        <div className="testi-img">
                          <img
                            src={`${BASE_URL}testimonial/uploads/${data[0].profile}`}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="avtar-bio">
                          <span className="testi-name text-capitalize">
                            {" "}
                            {data[0].userName}
                          </span>
                          <span className="testi-pos text-capitalize">
                            {" "}
                            {data[0].desigination}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {data.length > 1 && (
                <Slider {...settings}>
                  {data?.map((item, index) => (
                    <div className="item pb-5" key={item._id}>
                      <div className="testi-content">
                        <div className="testi-review-time">
                          {item.rating === "1" && (
                            <span className="testi-review">
                              <FaStar style={{ color: "#fe9704" }} />
                            </span>
                          )}
                          {item.rating === "2" && (
                            <span className="testi-review">
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                            </span>
                          )}
                          {item.rating === "3" && (
                            <span className="testi-review">
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                            </span>
                          )}
                          {item.rating === "4" && (
                            <span className="testi-review">
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                            </span>
                          )}
                          {item.rating === "5" && (
                            <span className="testi-review">
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                              <FaStar style={{ color: "#fe9704" }} />
                            </span>
                          )}
                        </div>

                        <div className="testi-desc">
                          <p>{item.feedback}</p>
                        </div>

                        <div className="testi-avtar">
                          <div className="avtar-img">
                            <div className="testi-img">
                              <img
                                src={`${BASE_URL}testimonial/uploads/${item.profile}`}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="avtar-bio">
                              <span className="testi-name text-capitalize">
                                {" "}
                                {item.userName}
                              </span>
                              <span className="testi-pos text-capitalize">
                                {" "}
                                {item.desigination}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Testimonial;
