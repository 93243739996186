import React from 'react'
import PropTypes from 'prop-types';

function OrderStatus({status}) {
  return (
    <>
    {status==="0" && (<div className='text-dark'>Pending</div>)}
    {status==="1" && (<div className='text-warning'>Dispatch</div>)}
    {status==="2" && (<div className='text-success'>Delivered</div>)}
    {status==="3" && (<div className='text-danger'>Canceled</div>)}
    </>
  )
}
OrderStatus.propTypes = {
  status: PropTypes.oneOf(["0", "1", "2", "3"]).isRequired,
};
export default OrderStatus