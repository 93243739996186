import React, { useEffect, useState } from "react";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
import { Container, Image } from "react-bootstrap";
import Breadcrumb from "../Components/Breadcrumb";
// import AboutArea from "../Components/about/AboutArea";
// import AboutProjectCount from "../Components/about/AboutProjectCount";
// import AboutVision from "../Components/about/AboutVision";
import PageTitle from "../Components/PageTitle";
import WhatsAppButton from "../Components/whatsapp-button/WhatsAppButton";
import cake from "../assets/img/about-us/about/hand-with-cake_plydrq.png";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function AboutUs() {
  const [page, setPage] = useState({});
  const [data, setData] = useState({});

  // const plateImage = require('../assets/img/about-us/about/plate_etplvs.png');
  // const fruitImage = require('../assets/img/about-us/about/Berry_blwvu1.png');

  useEffect(() => {
    const slug = window.location.pathname.slice(1);

    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => { });
    axios.get(BASE_URL + "about-us").then((response) => {
      if (response?.data?.result === true) {
        setData(response?.data?.data);
      }
    });
  }, []);
  return (
    <>
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <WhatsAppButton />
      <PageTitle pageData={page} />
      <Breadcrumb page="About Us" />
      {/* <AboutArea data={data} />
      <AboutVision data={data} />
      <AboutProjectCount data={data} /> */}
      <Container fluid className="about">
        <div className="title-head d-flex flex-wrap align-items-center justify-content-between">
          <div className="title col-md-6 mx-auto">
            <h3 className="main-title pb-4">Our Gooey Brownie</h3>
            <h3 className="sub-title">Dream? Story</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <Image src={cake} alt="cake-image" className="image img-fluid" />
          </div>
        </div>

        <div className="founder-title">
          <h3>Founder & Head Baker <span>Brindha</span></h3>
        </div>
        <Container >
          <div className="story">
            <p>{data.founderQuote}</p>
          </div>
        </Container>
        {/* <div className="promise d-flex flex-wrap align-items-center justify-content-between">
          <div className="col-md-4">
            <Image src={fruitImage} fluid alt="fruit-image" className="fruit-image img-fluid" />
          </div>
          <div className="col-md-8">
            <p><span>Our</span> Promise</p>
          </div>
        </div>

        <div className="quote">
          <p className="quote-one mt-3">{data.ourPromiseQuoteOne}</p>
          <Image src={plateImage} fluid alt="rolling-plate" className="plate-image d-block mx-auto img-fluid" />
          <p className="quote-two align-text-right mt-3">{data.ourPromiseQuoteTwo}</p>
          <p className="quote-three mt-3">{data.ourPromiseQuoteThree}</p>
        </div> */}
      </Container>
    </>
  );
}

export default AboutUs;
