import React from 'react'
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';


function PageTitle({pageData}) {
  return (
    <Helmet>
    <title>{pageData?.metaTitle}</title>
    <meta name="keywords" content={pageData?.keyWords} />
    <meta name="description" content={pageData?.metaDescription} />
  </Helmet>
  )
}
PageTitle.propTypes = {
  pageData: PropTypes.shape({
    metaTitle: PropTypes.string,
    keyWords: PropTypes.string,
    metaDescription: PropTypes.string,
  }),
};

export default PageTitle