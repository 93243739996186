import React, { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Container } from "react-bootstrap";
import Loader from "../Components/Loader";
import PageTitle from "../Components/PageTitle";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function ShippingPolicy() {
  const [page, setPage] = useState({});
  const [loding, setLoding] = useState(false);

  useEffect(() => {
    const slug = window.location.pathname.slice(1);
    setLoding(true)
    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => { });
    setLoding(false)
  }, []);

  return (
    <>
      {loding && <Loader />}
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <PageTitle pageData={page} />
      <Breadcrumb page="Shipping Policy" />
      <section className="terms-conditions">
      <div className="title">
          <h2 className="text-center ">Shipping Policy</h2>
          </div>
      <Container >
        <p className="mt-4">We aim to deliver your brownies fresh and on time.</p>
        <p className="sub-title font-bold mt-4">Shipping Methods</p>
        <p className="mt-2">We use reputable courier services to ensure safe and timely delivery.</p>
        <p className="sub-title font-bold mt-4">Shipping Timeframe</p>
        <p className="mt-2">Delivery times vary depending on your location. Typical delivery times are 2 business days from the date of ordering and will be delivered at the scheduled time.</p>
        <p className="sub-title mt-4">Shipping Costs</p>
        <p className="mt-2">Shipping costs are calculated based on your location and the size of your order.</p>
        <p className="sub-title font-bold mt-4">Shipping Restrictions</p>
        <p className="mt-2">We currently ship within Madurai and also can ship some items to various places within Tamilnadu. International shipping is not available.</p>
      </Container>
      </section>
    </>
  );
}

export default ShippingPolicy;
