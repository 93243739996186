import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Navbar, Nav } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LuHeadphones } from "react-icons/lu";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FiMenu } from "react-icons/fi";
import axios from "axios";
import { toast } from "react-toastify";
import { FaLocationDot } from "react-icons/fa6";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const NavBar = () => {
  const gooeyLogo = require("../assets/logo/logo.png");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [phno, setPhno] = useState("")

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop > lastScrollTop) {
        setIsNavbarVisible(false);
      } else {
        setIsNavbarVisible(true);
      }
      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);
  const storedValue = localStorage.getItem("user_id");

  useEffect(() => {
    axios
      .get(BASE_URL + "contact")
      .then((response) => {
        if (response?.data?.result === true) {
          setPhno(response.data.data.mobileNo1)
        }
      })
      .catch(() => {
      });
  }, [])

  const checkLogin = () => {
    if (!storedValue) {
      setTimeout(() => {
        toast.error("Please Login to Visit Cart", { theme: "colored" });
      }, 100);
      handleClose()
      navigate("/login");
    }
    else {
      navigate("/cart");
      handleClose()
    }
  }
  const checkLoginForAcc = () => {
    if (!storedValue) {
      setTimeout(() => {
        toast.error("Please Login to Visit Account", { theme: "colored" });
      }, 100);
      handleClose()
      navigate("/login");
    }
    else {
      navigate("/profile/my-profile");
      handleClose()
    }
  }
  return (
    <div className="navebar" style={isNavbarVisible ? { position: "", background: "white" } : {}}>
      <Navbar expand="lg" className="bg-body-bg-white">
        <Container>
          <Navbar.Brand as={NavLink} to="/home" className="sticky_logo">
            <img
              src={gooeyLogo}
              height="65"
              className="d-inline-block align-top"
              alt="gooey logo"
            />
          </Navbar.Brand>
          <div className="">
            <FiMenu className="nav-menu" onClick={handleShow} />

          </div>
          <Navbar.Collapse >
            <Nav className="mx-auto menu">
              <Nav.Item className="submenu">
                <NavLink to="/home" className="nav-link" activeclassname="active">
                  <span>Home</span>
                </NavLink>
              </Nav.Item>
              <Nav.Item className="submenu">
                <NavLink to="/about-us" className="nav-link" activeclassname="active">
                  <span>About Us</span>
                </NavLink>
              </Nav.Item>
              <Nav.Item className="submenu">
                <NavLink to="/products" className="nav-link" activeclassname="active">
                  <span>Products</span>
                </NavLink>
              </Nav.Item>
              <Nav.Item className="submenu">
                <NavLink to="/contact" className="nav-link" activeclassname="active">
                  <span>Contact</span>
                </NavLink>
              </Nav.Item>
              <Nav.Item className="submenu">
                <NavLink to="/customize-your-order" className="nav-link" activeclassname="active">
                  <span>Customize Your order</span>
                </NavLink>
              </Nav.Item>
              <Nav.Item className="submenu">
                <NavLink to="/suprise-gift" className="nav-link" activeclassname="active">
                  <span>Suprise Gift</span>
                </NavLink>
              </Nav.Item>
            </Nav>
            <div className="d-flex gap-5">
              <a href={`tel:${phno}`} className="text-black d-flex fs-5 text-decoration-none">
                <span className="fs-3 me-2">
                  <LuHeadphones />
                </span>
                <div className="d-block fs-6">
                  <div className="text-start">Call:</div>
                  <div>{phno}</div>
                </div>
              </a>
              <div className="my-auto">
                <Link to="/location"><FaLocationDot className="navbar-location-icon" /></Link>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menus</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="offcanvas-menu">
            <li><NavLink to="/home" onClick={handleClose} className="nav-link" activeclassname="active">
              <span>Home</span>
            </NavLink></li>
            <li><NavLink to="/about-us" onClick={handleClose} className="nav-link" activeclassname="active">
              <span>About Us</span>
            </NavLink></li>
            <li><NavLink to="/products" onClick={handleClose} className="nav-link" activeclassname="active">
              <span>Products</span>
            </NavLink></li>
            <li><NavLink to="/contact" onClick={handleClose} className="nav-link" activeclassname="active">
              <span>Contact</span>
            </NavLink></li>
            <li>
              <NavLink to="/customize-your-order" onClick={handleClose} className="nav-link" activeclassname="active">
                <span>Customize Your order</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/suprise-gift" onClick={handleClose} className="nav-link" activeclassname="active">
                <span>Suprise Gift</span>
              </NavLink>
            </li>
            <li><button className="bg-transparent border-0 text-black" onClick={checkLoginForAcc} >
              <span>Account</span>
            </button></li>
            {/* <li><NavLink to="/profile/wishlist" onClick={handleClose} className="nav-link" activeClassName="active">
                  <span>Wishlist</span>
                </NavLink></li> */}
            <li><button className="bg-transparent border-0 text-black" onClick={checkLogin} >
              <span>My Cart</span>
            </button></li>
            <li>
              <NavLink to="/location" onClick={handleClose} className="nav-link" activeclassname="active">
                <span>Location</span>
              </NavLink>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </div>


  );
};

export default NavBar;
