import React, { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Container } from "react-bootstrap";
import Loader from "../Components/Loader";
import PageTitle from "../Components/PageTitle";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function RefundPolicy() {
  const [page, setPage] = useState({});
  const [loding, setLoding] = useState(false);

  useEffect(() => {
    const slug = window.location.pathname.slice(1);
    setLoding(true)
    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => { });
    setLoding(false)
  }, []);

  return (
    <>
      {loding && <Loader />}
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <PageTitle pageData={page} />
      <Breadcrumb page="Refund Policy" />
      <section className="terms-conditions">
      <div className="title">
          <h2 className="text-center ">Refund Policy</h2>
          </div>
      <Container >
        <p className="mt-4">Due to the perishable nature of our brownies, we do not offer returns or cancellations. However, we understand that issues can arise.</p>
        <p className="sub-title font-bold mt-4">Damaged or Incorrect Orders</p>
        <p className="mt-2">If you receive damaged or incorrect products, please contact us within 48 hours of delivery with a photo of the item. We will arrange a replacement.</p>
        <p className="sub-title font-bold mt-4">Refund Processing</p>
        <p className="mt-2">We don't do refunds but in rare cases, we do it within 5-7 working days.</p>
        <p className="sub-title mt-4">Contact Information</p>
        <p className="mt-2">To request a refund, contact us at 9597229929.</p>
      </Container>
      </section>
    </>
  );
}

export default RefundPolicy;
