import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet, NavLink, Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import axios from "axios";
import { format } from "date-fns";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Profile() {
  const path = window.location.pathname;
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [joindate, setJoindate] = useState();
  // const [profile, setProfile] = useState("");

  useEffect(() => {
    const id = localStorage.getItem("user_id");

    if (!id) {
      navigate("/login");
    } else {
      axios.get(BASE_URL + `customer/${id}`).then((res) => {
        if (res?.data?.result === true) {
          setData(res.data.data);
          setJoindate(res?.data?.data?.createdAt);
        }
      });
    }
    // setProfile(sessionStorage.getItem("myProfile"));
  }, [navigate]);

  return (
    <>
      <Breadcrumb page="My Account" />
      <Container className="mb-5 pb-5 pt-5">
        <Row className="mt-5">
          <Col lg={4} className="justify-content-center me-2">
            <div className="profile">
              <div className="order-pro">
                <div className="pro-img">
                  {/* <img
                    src={`${BASE_URL}customer/uploads/${profile}`}
                    className="img-fluid prof"
                    alt="testi-1"
                  /> */}
                </div>
                <div className="order-name">
                  <h4 className="text-capitalize">{data.name}</h4>
                  <span>Joined {joindate ? format(new Date(joindate), 'dd-MM-yyyy') : ""}</span>
                </div>
              </div>
              <div className="order-his-page">
                <ul className="profile-ul">
                  <li
                    className={`profile-li ${path === "/profile/order" ? "active" : ""
                      }`}
                  >
                    <NavLink
                      to="/profile/order"
                      activeclassname="active-profile"
                    >
                      <span>Orders</span>
                    </NavLink>
                  </li>
                  <li
                    className={`profile-li ${path === "/profile/my-profile" ? "active" : ""
                      }`}
                  >
                    <Link to="/profile/my-profile">
                      <span>Profile</span>
                    </Link>
                  </li>
                  <li
                    className={`profile-li ${path === "/profile/address" ? "active" : ""
                      }`}
                  >
                    <Link to="/profile/address">
                      <span>Address</span>
                    </Link>
                  </li>

                  <li
                    className={`profile-li ${path === "/profile/change-password" ? "active" : ""
                      }`}
                  >
                    <Link to="/profile/change-password">
                      <span>Change password</span>
                    </Link>
                  </li>
                  <li className="profile-li">
                    <Link
                      to="/"
                      onClick={() => {
                        localStorage.removeItem("user_id");
                        localStorage.removeItem("myProduct");
                      }}
                    >
                      <span>Sign out</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>

          <Col>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;
