import React, { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Container } from "react-bootstrap";
import Loader from "../Components/Loader";
import PageTitle from "../Components/PageTitle";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Pricing() {
  const [page, setPage] = useState({});
  const [loding, setLoding] = useState(false);

  useEffect(() => {
    const slug = window.location.pathname.slice(1);
    setLoding(true)
    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => { });
    setLoding(false)
  }, []);

  return (
    <>
      {loding && <Loader />}
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <PageTitle pageData={page} />
      <Breadcrumb page="Pricing" />
      <section className="terms-conditions">
      <div className="title">
          <h2 className="text-center ">Pricing</h2>
          </div>
      <Container >
        <p className="mt-4">Our pricing is competitive and based on the quality of our products. All prices are in INR and include taxes but exclude shipping costs.</p>
        <p className="sub-title font-bold mt-4">Price Changes</p>
        <p className="mt-2">Prices are subject to change without notice, but the price at the time of order will be honored.</p>
        <p className="sub-title font-bold mt-4">Discounts and Promotions</p>
        <p className="mt-2">We offer various discounts and promotions. These are subject to terms and conditions specified at the time of the offer.</p>
      </Container>
      </section>
    </>
  );
}

export default Pricing;
