
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
};

const ProductSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.count=action.payload;
    },
    
  },
});

export const { addItem } = ProductSlice.actions;

export default ProductSlice.reducer;
