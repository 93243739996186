import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CiMail } from "react-icons/ci";
import {useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../slice/ProductCountSlice";
import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Header() {
  const [email, setEmail] = useState("");
  const count = useSelector((state) => state.product.count);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const storedProductJSON = localStorage.getItem("myProduct");
    const storedProduct = JSON.parse(storedProductJSON);
    if (storedProduct) {
      dispatch(addItem(storedProduct.length));
    }
    axios
      .get(BASE_URL + "contact")
      .then((response) => {
        if (response?.data?.result === true) {
          setEmail(response.data.data.email1);
        }
      })
      .catch(() => {});
  }, [dispatch]);
  const storedValue = localStorage.getItem("user_id");

  const checkLogin=()=>{
    if (!storedValue) {
      setTimeout(() => {
        toast.error("Please Login to Visit Cart", { theme: "colored" });
      }, 100);
      navigate("/login");
      return false;
    }
    else{
      navigate("/cart");
    }
  }
   const checkLoginForAcc=()=>{
    if (!storedValue) {
      setTimeout(() => {
        toast.error("Please Login to Visit Account", { theme: "colored" });
      }, 100);
      navigate("/login");
      return false;
    }
    else{
      navigate("/profile/my-profile");
    }
      
   }
  return (
    <div className="top-notification-bar">
      <Container fluid>
        <Row>
          <Col>
            <div className="text-start p-2">
              <a
                href={`mailto:${email}`}
                target="_blank" rel="noopener noreferrer"
                className="fs-6 text-white text-decoration-none header-link "
              >
                <CiMail className="mt-1 mx-2 fs-4 animated-mail" />
                <span>{email}</span>
              </a>
            </div>
          </Col>
          <Col className="text-end p-2 me-3 head-menu" lg={3}>
            <div className="d-flex justify-content-end fs-6  ">
            <button
                className="my-cart"
                onClick={checkLoginForAcc}
              >
                <FaRegUser /> Account
                </button>
              {/* <Link to='/profile/wishlist' className='text-white text-decoration-none   header-link'><FaRegHeart /> Wishlist</Link> */}
              <button
                className="my-cart"
                onClick={checkLogin}
              >
                <FiShoppingCart /> My Cart
                {count >= 1 && <span className="pro-count">{count}</span>}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
