import React from 'react'
import Breadcrumb from '../Components/Breadcrumb'
import AllProduct from '../Components/product/AllProduct'
function Products() {
  return (
    <>
    <Breadcrumb page="Products"/>
    <AllProduct/>
    </>
  )
}

export default Products