import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function  BakeryCollection({ data }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    if (data?.homeQuotes) {
      const quotesArray = data.homeQuotes.split('\n').map(quote => quote.trim().slice(1,-2));
      setQuotes(quotesArray);

      const intervalId = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % quotesArray.length);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [data]);

  return (
    <Container className='my-5'>
      <Row>
        <Col lg={6} >
          <div className='banner-main justify-content-md-center my-5'>
            <span className='full-img'><Image src={`${BASE_URL}home/uploads/${data.collImg1}`} alt='collection img' className='w-75 fullimg' fluid /></span>
            <div className='banner-side'><Image src={`${BASE_URL}home/uploads/${data.collImg2}`} className='bannerimg' alt='collection img' fluid /></div>
          </div>
        </Col>
        <Col lg={6} className='justify-content-md-center my-5 '>
          <h4 className='collection-title mt-md-5'>
            Best ever <span>Uniquely</span>
          </h4>
          <h4 className='collection-title mt-md-3'>
            <span>Crafted </span> Brownies
          </h4>
          <p className='collection-desc mt-4'>{data.collectionDescription}</p>
          {/* <div className='btn-read'><Link to={`${data.CollectionUrl}`} className='text-decoration-none text-white fs-5'>Read More</Link></div> */}
        </Col>
      </Row>
      <Col className='brand-introduction'>
        <p className='text-center brand-introduction-title'><span>Why</span> Choose Gooey <br /> Brownies</p>
        <p className='text-center brand-introduction-quote animated-slide'>{quotes[currentIndex]}</p>
      </Col>
    </Container>
  )
}
BakeryCollection.propTypes = {
  data: PropTypes.shape({
    collImg1: PropTypes.string,
    collImg2: PropTypes.string,
    collectionTitle: PropTypes.string,
    collectionDescription: PropTypes.string,
    CollectionUrl: PropTypes.string,
    homeQuotes: PropTypes.string,
  }).isRequired,
};

export default BakeryCollection