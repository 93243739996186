import React, { useState, useEffect } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link ,useNavigate} from "react-router-dom";
import { useForm } from "react-hook-form";
import Loader from "../Components/Loader";
import axios from "axios";
import PageTitle from "../Components/PageTitle";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
function SignUp() {
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [err, setErr] = useState();
  const [loding, setLoding] = useState(false);
  const [page, setPage] = useState({});

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    const slug = window.location.pathname.slice(1);

    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => {});
  }, []);
  const handleToggleForm = () => {
    setShowLoginForm(!showLoginForm);
  };
  
  const signUp = (data) => {
    setLoding(true);
    setErr("");
    axios
      .post(BASE_URL + "customer", data)
      .then((res) => {
        if (res?.data?.result === true) {
          setLoding(false);
          navigate("/login");
        } else if (res?.data?.result === false) {
          setErr(res.data.errors);
          setLoding(false);
        }
      })
      .catch(() => {
        setLoding(false);
      });
  };

  return (
    <>
      <PageTitle pageData={page} />
      <Breadcrumb page="Create Account" />
      <section className="customer-page section-ptb">
        <Container>
          {loding && <Loader />}
          <Row>
            <Col>
              <div className="acc-form">
                <div className="log-acc-page" id="CustomerLoginForm">
                  {/* account title start */}
                  <div className="content-main-title">
                    <div className="section-cont-title text-center">
                      <h2>
                        <span>Create account</span>
                      </h2>
                      <p>Please register account detail</p>
                    </div>
                  </div>
                  {/* account title end */}
                  {/* account login start */}
                  <div className="acc-page">
                    <div className="login">
                      <Form onSubmit={handleSubmit(signUp)}>
                        <div className="login-form-container">
                        <Form.Group className="log-email mt-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        style={{
                          border: "1px solid rgba(130, 88, 70, 0.8)",
                          borderRadius: "0px",
                        }}
                        type="text"
                        {...register("name", {
                          required: "Name is required",
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message:
                              "Name must only contain alphabetic characters and spaces",
                          },
                        })}
                        placeholder="Enter Your Name"
                        name="name"
                        isInvalid={errors?.name}
                      ></Form.Control>
                      {errors?.name && (
                        <span className="text-danger">
                          {errors?.name?.message}
                        </span>
                      )}
                      {err?.name && (
                        <span className="text-danger">{err?.name}</span>
                      )}
                    </Form.Group>

                          <Form.Group className="log-email mt-3">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                              style={{
                                border: "1px solid rgba(130, 88, 70, 0.8)",
                                borderRadius: "0px",
                              }}
                              placeholder="Enter Your Mobile Number"
                              type="text"
                              {...register("mobileNo", {
                                required: "Phone number is required",
                                pattern: {
                                  value: /^\d+$/,
                                  message:
                                    "Mobile number must only contain numeric digits",
                                },
                                minLength: {
                                  value: 10,
                                  message:
                                    "Mobile number must be exactly 10 digits",
                                },
                                maxLength: {
                                  value: 10,
                                  message:
                                    "Mobile number must be exactly 10 digits",
                                },
                              })}
                              name="mobileNo"
                              isInvalid={errors?.mobileNo}
                            ></Form.Control>
                            {errors?.mobileNo && (
                              <span className="text-danger">
                                {errors?.mobileNo?.message}
                              </span>
                            )}
                            {err?.mobileNo && (
                              <span className="text-danger">
                                {err?.mobileNo}
                              </span>
                            )}
                          </Form.Group>
                          <Form.Group className="log-email mt-3">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                style={{
                                  border: "1px solid rgba(130, 88, 70, 0.8)",
                                  borderRadius: "0px",
                                }}
                                type="email"
                                {...register("email", {
                                  required: "Email is required",
                                  pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Invalid email address",
                                  },
                                })}
                                placeholder="Enter Your Email"
                                isInvalid={errors?.email}
                              ></Form.Control>
                              {errors?.email && (
                                <span className="text-danger">
                                  {errors?.email?.message}
                                </span>
                              )}
                              {err?.email && (
                              <span className="text-danger">
                                {err?.email}
                              </span>
                            )}
                            </Form.Group>
                          <Form.Group className="log-email mt-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              style={{
                                border: "1px solid rgba(130, 88, 70, 0.8)",
                                borderRadius: "0px",
                              }}
                              type="password"
                              placeholder="Enter Password"
                              {...register("password", {
                                required: "Password is Required",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Password must be minimum 8 characters",
                                },
                                validate: (value) => {
                                  const regex =
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;
                                  
                                    if(!regex.test(value)){
                                      return ("Password must contain at least one uppercase, symbol, and number");
                                    }
                                  
                                },
                              })}
                              isInvalid={errors?.password}
                            />
                            {errors?.password?.message && (
                              <span className="text-danger">
                                {errors.password.message}
                              </span>
                            )}
                            {err?.password && (
                              <span className="text-danger">
                                {err?.password}
                              </span>
                            )}
                          </Form.Group>

                          <Form.Group className="log-email mt-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              style={{
                                border: "1px solid rgba(130, 88, 70, 0.8)",
                                borderRadius: "0px",
                              }}
                              type="password"
                              placeholder="Confirm Password"
                              {...register("confirmPassword", {
                                required: "Confirm Password is Required",
                                validate: (value) =>
                                  value === getValues("password") ||
                                  "Passwords do not match",
                              })}
                              isInvalid={errors?.confirmPassword}
                            />
                            {errors?.confirmPassword && (
                              <span className="text-danger">
                                {errors.confirmPassword.message}
                              </span>
                            )}
                          </Form.Group>

                          <div className="form-action-button">
                            <Button
                              className="account-btn-create fs-5 "
                              type="submit"
                            >
                              Create
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="acc-wrapper mb-5 ">
                      <h6 className="mt-3">Already have account?</h6>
                      <div className="account-optional-action mt-0">
                        <Link to="/login" className="text-decoration-none">
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* account login end */}
                </div>

                <div
                  className="log-acc-page"
                  id="RecoverPasswordForm"
                  style={{ display: showLoginForm ? "none" : "block" }}
                >
                  {/* account title start */}
                  <div className="content-main-title">
                    <div className="section-cont-title">
                      <h2>
                        <span className="title-main">Reset your password</span>
                      </h2>
                      <p>We will send you an email to reset your password.</p>
                    </div>
                  </div>
                  {/* account title end */}
                  {/* account login start */}
                  <div className="acc-page">
                    <div className="login">
                      <Form>
                        <div className="login-form-container">
                          <Form.Group
                            controlId="formEmail"
                            className="log-email"
                          >
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email address"
                              autoComplete="off"
                            />
                          </Form.Group>

                          <div className="form-toggle-btn">
                            <div className="form-action-button">
                              <Button type="submit" className="btn btn-style2">
                                Submit
                              </Button>
                              <Button variant="link" onClick={handleToggleForm}>
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                  {/* account login end */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default SignUp;
