import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { Carousel, Container, Image } from 'react-bootstrap';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const ProductSlider = () => {
    const [productDetail, setProductDetail] = useState([]);
    const fetchData = useCallback(async()=>{
        const response = await axios.get(BASE_URL + "product");
        setProductDetail(response.data.data.filter((item) => item.displayHome === true))
    }, []);
    
    useEffect(()=>{
        fetchData()
    }, [fetchData])

    return (
        <Container className='main-product-slider'>
            <Carousel className="product-slider">
                {productDetail?.map((product) => (
                    <Carousel.Item key={product._id}>
                        <Container className="d-flex flex-wrap justify-content-between">
                            <div className="carousel-text my-auto col-md-6">
                                <h5 className="product-title">{product.productName}</h5>
                                <p className="product-description">{product.description}</p>
                            </div>
                            <div className="d-flex">
                                <Image
                                    className="d-block img-fluid"
                                    src={`${BASE_URL}product/uploads/${product.coverImg}`}
                                    alt={`p-${product.productName}`}
                                />
                            </div>
                        </Container>
                    </Carousel.Item>
                ))}
            </Carousel>
        </Container>
    );
}

export default ProductSlider;
