import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Breadcrumb = ({ page }) => {
  return (
    <section className="breadcrumb-area">
      <Container>
        <Row>
          <Col>
            <div className="breadcrumb-index">
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item-link">
                  <Link to="/home" className="text-decoration-none fs-6">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item-link ">
                  <span className="fs-6">{page}</span>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
Breadcrumb.propTypes = {
  page: PropTypes.string.isRequired,
};

export default Breadcrumb;
