import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import OrderStatus from "../OrderStatus";
import { Link } from "react-router-dom";
import PageTitle from "../PageTitle";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const columns = [
  {
    name: "Order No",
    cell: (row) => (
      <Link
        to={`/profile/order-detail/${row._id}`}
        className="text-decoration-none text-black"
      >
        {row.orderNo}
      </Link>
    ),
  },
  {
    name: "Date purchased",
    selector: (row) => {
      const parsedDate = new Date(row.orderDate);
      return parsedDate.toISOString().split("T")[0];
    },
  },
  {
    name: "Status",
    cell: (row, index) => <OrderStatus status={row.deliveryStatus} />,
  },
  {
    name: "Total",
    selector: (row) => row.grandTotal,
  },
];

function Order() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState({});

  useEffect(() => {
    const id = localStorage.getItem("user_id");

    axios.get(BASE_URL + `orders/customer-detail/${id}`).then((res) => {
      if (res?.data?.result === true) {
        const sortedData = res.data.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setData(sortedData);

      }
    });
    const path = window.location.pathname.slice(1);
    const parts = path.split("/");
    const slug = parts[1];

    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => {});
  }, []);
 

  return (
    <>
      <PageTitle pageData={page} />
      <div className="profile-border">Order</div>
      <DataTable highlightOnHover columns={columns} data={data} className="custom-table" />
    </>
  );
}

export default Order;
