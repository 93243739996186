import React from "react";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import PropTypes from "prop-types";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <IoIosArrowBack
    {...props}
    className={
      "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0}
    type="button"
  />
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <IoIosArrowForward
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1}
    type="button"
  />
);

const ProImgSlide = ({ images, setImg }) => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: images.length,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };
  

  return (
    <Slider {...settings}>
      {images?.map((item, index) => (
          <button
          key={`image-${index}`} 
          className="d-flex justify-content-center border-0 bg-transparent"
          onClick={() => {
            setImg(item);
          }}
        >
          <img
            src={`${BASE_URL}product/uploads/${item}`}
            className="img-fluid border text-center w-75 mb-1"
            alt="product img"
          ></img>
        </button>
      ))}
    </Slider>
  );
};

SlickArrowLeft.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
};
SlickArrowRight.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
};

ProImgSlide.propTypes = {
  images: PropTypes.array.isRequired,
  setImg: PropTypes.func.isRequired,
};

export default ProImgSlide;
