import React, { useState, useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import Slider from "react-slick";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function BannerSlider() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(BASE_URL + `banner`)
      .then((response) => {
        if (response?.data?.result === true) {
          const sortedData = response.data.data.filter(
            (item) => item.status === "Active"
          );

          sortedData.sort(
            (a, b) => parseInt(a.priority, 10) - parseInt(b.priority, 10)
          );
          setData(sortedData);
        }
      })
      
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {data.length === 1 && (
        <div className="slider">
          <Image
            src={`${BASE_URL}banner/uploads/${data[0].deskImg}`}
            alt={`Image`}
            className="desk-img-slider"
            fluid
          />
          <Image
            src={`${BASE_URL}banner/uploads/${data[0].mobileImg}`}
            alt={`Image`}
            className="mobile-img-slider"
            fluid
          />
          <Container fluid>
            <Row>
              <Col>
                <div className="slider-text-info">
                  <div className="image-overlay-subtitle slide-anim text-capitalize">
                    {data[0].title}
                  </div>
                  <h2 className="image-overlay-heading slide-anim text-capitalize">
                    {data[0].subTitle}
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {data.length > 1 && (
        <Slider {...settings} className="slick-slid">
          {data?.map((item, index) => (
            <div key={item._id} className="slider">
              <Image
                src={`${BASE_URL}banner/uploads/${item.deskImg}`}
                alt={`Image`}
                className="desk-img-slider"
                fluid
              />
              <Image
                src={`${BASE_URL}banner/uploads/${item.mobileImg}`}
                alt={`Image`}
                className="mobile-img-slider"
                fluid
              />
              <Container fluid>
                <Row>
                  <Col>
                    <div className="slider-text-info">
                      <div className="image-overlay-subtitle slide-anim text-capitalize">
                        {item.title}
                      </div>
                      <h2 className="image-overlay-heading slide-anim text-capitalize text-center w-100">
                        {item.subTitle}
                      </h2>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}

export default BannerSlider;
