import React, { useEffect, useState } from "react";
import Slider from "../Components/home/Slider";
import BakeryCollection from "../Components/home/BakeryCollection";
import FeatureProduct from "../Components/home/FeatureProduct";
import OurService from "../Components/home/OurService";
import Testimonial from "../Components/home/Testimonial";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { addItem } from "../slice/ProductCountSlice";
import PageTitle from "../Components/PageTitle";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
import ProductSlider from "../Components/home/ProductSlider";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;
function Home() {
  const dispatch = useDispatch();
  const [page, setPage] = useState({});
  const [data, setData] = useState({});
  useEffect(() => {
    const slug = window.location.pathname.slice(1);

    axios.get(BASE_URL + `seo/find-slug/${slug}`).then((res) => {
      if (res?.data?.result === true) {
        setPage(res.data.data);
      }
    });

    axios.get(BASE_URL + `home`).then((res) => {
      if (res?.data?.result === true) {
        setData(res.data.data);
      }
    });
    let arrayOfProduct = JSON.parse(localStorage.getItem("myProduct")) || [];
    if (arrayOfProduct) {
      dispatch(addItem(arrayOfProduct.length));
    }
  }, [dispatch]);
  return (
    <>
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <PageTitle pageData={page} />
      <Slider />
      <BakeryCollection data={data} />
      <FeatureProduct detail={data} />
      <OurService data={data} />
      <Testimonial />
      <ProductSlider/>
      <ToastContainer />
    </>
  );
}

export default Home;
