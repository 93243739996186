import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PageTitle from "../PageTitle";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function ChangePass() {
  const [err, setErr] = useState();
  const [loding, setLoding] = useState(false);
  const [page, setPage] = useState({});

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    const path = window.location.pathname.slice(1);
    const parts = path.split("/");
    const slug = parts[1];

    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => {});
  }, []);
  const submit = (data) => {
    setLoding(true);
    setErr("");
    axios
      .put(BASE_URL + `customer`, data)
      .then((res) => {
        if (res?.data?.result === true) {
          setLoding(false);
          setTimeout(() => {
            toast.success(res.data.message, { theme: "colored" });
          }, 100);
          navigate("/profile/change-password");
        } else if (res?.data?.result === false) {
          setErr(res.data.errors);
          setLoding(false);
        }
      })
      .catch(() => {
        setLoding(false);
      });
  };

  return (
    <>
      <Container className="">
        <PageTitle pageData={page} />
        {loding && <Loader />}
        <Row>
          <Col lg={12}>
            <div className="profile-border">Change Password</div>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(submit)}>
          <div className="login-form-container">
            <Row>
              <Col lg={6}>
                <Row>
                  <Col>
                    <Form.Group className="log-email mt-3">
                      <Form.Label>Email</Form.Label>

                      <Form.Control
                        style={{
                          border: "1px solid rgba(130, 88, 70, 0.8)",
                          borderRadius: "0px",
                        }}
                        type="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                          },
                        })}
                        placeholder="Enter Your Email"
                        isInvalid={errors?.email}
                      ></Form.Control>
                      {errors?.email && (
                        <span className="text-danger">
                          {errors?.email?.message}
                        </span>
                      )}
                      {err?.email && (
                        <span className="text-danger">{err?.email}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                  <Form.Group className="log-email mt-3">
                  <Form.Label>Old Password</Form.Label>

                      <Form.Control
                        style={{
                          border: "1px solid rgba(130, 88, 70, 0.8)",
                          borderRadius: "0px",
                        }}
                        type="password"
                        placeholder="Enter Password"
                        {...register("oldPassword", {
                          required: "Password is Required",
                          minLength: {
                            value: 8,
                            message: "Password must be minimum 8 characters",
                          },
                          validate: (value) => {
                            const regex =
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;

                            if (!regex.test(value)) {
                              return "Password must contain at least one uppercase, symbol, and number";
                            }
                          },
                        })}
                        isInvalid={errors?.oldPassword}
                      />
                      {errors?.oldPassword?.message && (
                        <span className="text-danger">
                          {errors.oldPassword.message}
                        </span>
                      )}
                      {err?.oldPassword && (
                        <span className="text-danger">{err?.oldPassword}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="log-email mt-3">
                      <Form.Label>New Password</Form.Label>

                      <Form.Control
                        style={{
                          border: "1px solid rgba(130, 88, 70, 0.8)",
                          borderRadius: "0px",
                        }}
                        type="password"
                        placeholder="Enter Password"
                        {...register("newPassword", {
                          required: "Password is Required",
                          minLength: {
                            value: 8,
                            message: "Password must be minimum 8 characters",
                          },
                          validate: (value) => {
                            const regex =
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;

                            if (!regex.test(value)) {
                              return "Password must contain at least one uppercase, symbol, and number";
                            }
                          },
                        })}
                        isInvalid={errors?.newPassword}
                      />
                      {errors?.newPassword?.message && (
                        <span className="text-danger">
                          {errors.newPassword.message}
                        </span>
                      )}
                      {err?.newPassword && (
                        <span className="text-danger">{err?.newPassword}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-action-button mt-4">
                  <Button
                    className="account-btn fs-5 w-auto mb-4  "
                    type="submit"
                  >
                    Update Profile
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
}

export default ChangePass;
