import React, { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Container } from "react-bootstrap";
import Loader from "../Components/Loader";
import PageTitle from "../Components/PageTitle";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function TermsAndConditions() {
  const [page, setPage] = useState({});
  const [loding, setLoding] = useState(false);

  useEffect(() => {
    const slug = window.location.pathname.slice(1);
    setLoding(true)
    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => { });
    setLoding(false)
  }, []);

  return (
    <>
      {loding && <Loader />}
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <PageTitle pageData={page} />
      <Breadcrumb page="Terms And Conditions" />
      <section className="terms-conditions">
      <div className="title">
          <h2 className="text-center ">Terms and Conditions</h2>
          </div>
      <Container >
        <p className="mt-4">Welcome to Gooey Brownies! By accessing or using our website, you agree to abide by these Terms and Conditions. Please read them carefully.</p>
        <p className="sub-title font-bold mt-4">Product Description</p>
        <p className="mt-2">We specialize in a variety of gourmet brownies. We strive to provide accurate product descriptions and images, but slight variations may occur.</p>
        <p className="sub-title font-bold mt-4">Ordering and Payment</p>
        <p className="mt-2">All orders are subject to acceptance and availability. Payment must be made at the time of order. We accept [list payment methods].</p>
        <p className="sub-title mt-4">User Responsibilities</p>
        <p className="mt-2">By using our website, you agree to provide accurate information and not engage in fraudulent activity.</p>
        <p className="sub-title font-bold mt-4">Intellectual Property</p>
        <p className="mt-2">All content on this website, including text, images, logos, and branding, is owned by Gooey Brownies and cannot be used without permission.</p>
        <p className="sub-title font-bold mt-4">Limitation of Liability</p>
        <p className="mt-2">Gooey Brownies is not liable for any direct or indirect damages arising from the use of our website or products, except where required by law.</p>
      </Container>
      </section>
    </>
  );
}

export default TermsAndConditions;
