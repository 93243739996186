import React, { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Container } from "react-bootstrap";
import Loader from "../Components/Loader";
import PageTitle from "../Components/PageTitle";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function PrivacyPolicy() {
  const [page, setPage] = useState({});
  const [loding, setLoding] = useState(false);

  useEffect(() => {
    const slug = window.location.pathname.slice(1);
    setLoding(true)
    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => { });
    setLoding(false)
  }, []);

  return (
    <>
      {loding && <Loader />}
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <PageTitle pageData={page} />
      <Breadcrumb page="Privacy Policy" />
      <section className="terms-conditions">
      <div className="title">
          <h2 className="text-center ">Privacy Policy</h2>
          </div>
      <Container >
        <p className="mt-4">At Gooey Brownies, we value your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information.</p>
        <p className="sub-title font-bold mt-4">Information Collection</p>
        <p className="mt-2">We collect information you provide during registration, ordering, or customer service interactions. This may include your name, email, address, and payment details.</p>
        <p className="sub-title font-bold mt-4">Use of Information</p>
        <p className="mt-2">We use your information to process orders, improve our services, and communicate with you. We do not sell or share your information with third parties without your consent, except as required to process orders or by law.</p>
        <p className="sub-title mt-4">Data Security</p>
        <p className="mt-2">We use industry-standard security measures to protect your information. However, no system is 100% secure.</p>
        <p className="sub-title font-bold mt-4">Cookies and Tracking</p>
        <p className="mt-2">Our website may use cookies to enhance your experience. You can disable cookies in your browser settings.</p>
        <p className="sub-title font-bold mt-4">Your Rights</p>
        <p className="mt-2">You have the right to access, correct, or request deletion of your personal information. Contact us to exercise these rights.</p>
      </Container>
      </section>
    </>
  );
}

export default PrivacyPolicy;
