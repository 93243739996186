import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PageTitle from "../../Components/PageTitle";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Customize() {
  useEffect(() => {
    axios
      .get(BASE_URL + "category")
      .then((res) => {
        if (res?.data?.result === true) {
          setCategory(res.data.data);
        }
      })
      .catch(() => {});

    const slug = window.location.pathname.slice(1);

    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => {});
  }, []);
  const navigate = useNavigate();
  const [err, setErr] = useState();
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState({});

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  const handleStateChange = (e, field) => {
    let selectedValue = e.target.value;
    field.onChange(selectedValue);
    axios
      .get(BASE_URL + `product/find-category/${selectedValue}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setProducts(res.data.data);
        }
      });
  };
  const saveData = (data) => {
    const id = localStorage.getItem("user_id");

    if (!id) {
      setTimeout(() => {
        toast.error("Please Login to Customize Your Order ", { theme: "colored" });
      }, 100);
      navigate("/login");
    } else {
      data.customerId = id;

      axios
        .post(BASE_URL + `customize`, data)
        .then((res) => {
          if (res?.data?.result === true) {
            navigate("/customize-thanks");
          } else if (res?.data?.result === false) {
            setErr(res.data.errors);
          }
        })
        .catch(() => {});
    }
  };
  return (
    <>
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <section className="product-features pb-5 ">
        <PageTitle pageData={page} />
        <Container>
          <Card className=" mb-5">
            <Card.Body className="my-5  ">
              <div className="fs-2 text-center fw-bold">
                Customize Your Order
              </div>
              <Form autoComplete="off" onSubmit={handleSubmit(saveData)}>
                <Row className="justify-content-center mt-4">
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Category</Form.Label>

                      <Controller
                        name="categoryName"
                        control={control}
                        rules={{ required: "Category is required" }}
                        defaultValue=""
                        render={({ field }) => (
                          <div>
                            <Form.Select
                              {...field}
                              onChange={(e) => handleStateChange(e, field)}
                              style={{
                                border: "1px solid rgba(130, 88, 70, 0.8)",
                                borderRadius: "0px",
                              }}
                            >
                              <option value="" disabled>
                                Select an option
                              </option>
                              {category?.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.categoryName}
                                </option>
                              ))}
                            </Form.Select>

                            {/* Updated validation error rendering */}
                            {errors.categoryName && (
                              <span className="text-danger">
                                {errors?.categoryName?.message}
                              </span>
                            )}

                            {err?.categoryName && (
                              <span className="text-danger">
                                {err.categoryName}
                              </span>
                            )}
                          </div>
                        )}
                      />
                    </Form.Group>

                    <Form.Group className="log-email mb-3">
                      <Form.Label>Product</Form.Label>
                      <Controller
                        name="productName"
                        control={control}
                        rules={{
                          validate: (value) => {
                            if (value && value !== "Select an option") {
                              return null; 
                            } else {
                              return "Product is required"; 
                            }
                          },
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Select
                            {...field}
                            style={{
                              border: "1px solid rgba(130, 88, 70, 0.8)",
                              borderRadius: "0px",
                            }}
                          >
                            <option value={null}>Select an option</option>
                            {products?.map((item, index) => (
                              <option key={item._id} value={item.productName}>
                                {item.productName}
                              </option>
                            ))}
                          </Form.Select>
                        )}
                      />
                      {errors?.productName && (
                        <span className="text-danger">
                          {errors.productName.message}
                        </span>
                      )}
                      {err?.productName && (
                        <span className="text-danger">{err.productName}</span>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-5">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        style={{
                          height: "180px",
                          border: "1px solid rgba(130, 88, 70, 0.8)",
                          borderRadius: "0px",
                        }}
                        {...register("description", {
                          required: "Description is required",
                        })}
                        isInvalid={errors?.description}
                      ></Form.Control>
                      {errors?.description && (
                        <span className="text-danger">
                          { errors?.description?.message}
                        </span>
                      )}
                      {err?.description && (
                        <span className="text-danger">{err?.description}</span>
                      )}
                    </Form.Group>
                    <div className="form-action-button mt-4 text-center">
                      <Button
                        className="account-btn fs-5 w-auto mb-4 rounded"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                  <Row>
                    <Col></Col>
                  </Row>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default Customize;
