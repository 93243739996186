import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../Breadcrumb";
import { Col, Container, Row, Form, Dropdown } from "react-bootstrap";
import ProImgSlide from "./ProImgSlide";
import IncDec from "../IncDec";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ImageZoom from "react-image-zooom";
import Loader from "../Loader";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addItem } from "../../slice/ProductCountSlice";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function ProductDetail() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { handleSubmit } = useForm({ mode: "all" });

	const { id } = useParams();

	const [detail, setDetail] = useState({});
	const [allImages, setAllImages] = useState([]);
	const [loding, setLoding] = useState(false);
	const [price, setPrice] = useState("");
	const [size, setSize] = useState("");
	const [pieces, setPieces] = useState("");
	const [quantity, setQuantity] = useState(1);
	const [uniquePieces, setUniquePieces] = useState([]);
	const [uniqueSizes, setUniqueSizes] = useState([]);
	const [hideSize, setHideSize] = useState(false)

	const handleSizeOptionSelect = (option) => {
		const hasPieces = detail?.variant.some(item => item.pieces !== "");
		const hasSize = detail?.variant.some(item => item.size !== "");

		if (hasPieces && !hasSize) {
			const filteredProducts = detail?.variant.filter((item) => item.pieces === option);
			const sizes = filteredProducts.map(item => item.size);
			setUniqueSizes(sizes);
			setPieces(option);
			setHideSize(false);
			const findPrice = filteredProducts.find((item) => item.size === sizes);
			setPrice(findPrice ? findPrice.price : null);
		} else if (!hasPieces && hasSize) {
			const foundOption = detail?.variant.find((item) => item.size === option);
			if (foundOption) {
				setPrice(foundOption.price);
				setSize(foundOption.size);
				setHideSize(false);
			}
		} else if (hasPieces && hasSize) {
			const filteredProducts = detail?.variant.filter((item) => item.size === option);

			if (filteredProducts.length > 0) {
				const piece = filteredProducts.map(item => item.pieces);
				setUniquePieces(piece);
				setSize(option);
				setHideSize(false);

				const findPrice = filteredProducts.find((item) => item.pieces === pieces && item.size === option);
				setPrice(findPrice ? findPrice.price : null);
			}
		}
	};

	const handlePieceOptionSelect = (option) => {
		// Check if any element in detail.variant has a value for pieces or size
		const hasPieces = detail?.variant.some(item => item.pieces !== "");
		const hasSize = detail?.variant.some(item => item.size !== "");

		if (hasPieces && !hasSize) {
			// If any element has a value for pieces but none has a value for size
			const foundOption = detail?.variant.find((item) => item.pieces === option);
			if (foundOption) {
				setUniqueSizes([foundOption.size]);
				setPieces(foundOption.pieces);
				setPrice(foundOption.price);
				setHideSize(false);
			}
		} else if (!hasPieces && hasSize) {
			// If any element has a value for size but none has a value for pieces
			const filteredProducts = detail?.variant.filter((item) => item.size === option);
			const piece = filteredProducts.map(item => item.pieces);
			setUniquePieces(piece);
			setPieces(option);
			setHideSize(false);
			const findPrice = filteredProducts.find((item) => item.pieces === pieces);
			setPrice(findPrice ? findPrice.price : null);
		} else if (hasPieces && hasSize) {
			// If any element has a value for both pieces and size
			const filteredProducts = detail?.variant.filter((item) => item.pieces === option);
			const sizes = filteredProducts.map(item => item.size);
			setUniqueSizes(sizes);
			setPieces(option);
			setHideSize(false);
			const findPrice = filteredProducts.find((item) => item.size === size);
			setPrice(findPrice ? findPrice.price : null);
		}
	};


	const [img, setImg] = useState("");

	const getUniquePieces = (variantData) => {
		const piecesArray = variantData.map(item => item.pieces);

		const uniquePiecesArray = [...new Set(piecesArray)];

		return uniquePiecesArray;
	};
	const getUniqueSizes = (variantData) => {
		const sizeArray = variantData.map(item => item.size);

		const uniqueSizeArray = [...new Set(sizeArray)];

		return uniqueSizeArray;
	};

	const getData = useCallback(async () => {
		setLoding(true);
		try {
			const res = await axios.get(BASE_URL + `product/${id}`);
			if (res?.data?.result === true) {
				let coverImg = res.data.data.coverImg;
				const images = Array.isArray(res?.data?.data.images) ? res.data.data.images : [];
				const allImagesArray = [coverImg, ...images];
				setDetail(res?.data?.data);
				setImg(res?.data?.data?.coverImg);
				setAllImages(allImagesArray);
				setDetail(res?.data?.data);
				setImg(res.data.data.coverImg);
				const uniquePieces = getUniquePieces(res?.data?.data?.variant);
				const uniqueSizes = getUniqueSizes(res?.data?.data?.variant);

				const variant = res?.data?.data?.variant;

				const hasPieces = variant.some(item => item.pieces !== "");
				const hasPrice = variant.some(item => item.price !== "");

				if (hasPieces && !hasPrice) {
					setUniquePieces(uniquePieces);
				} else if (!hasPieces && hasPrice) {
					setUniqueSizes(uniqueSizes);
				} else if (hasPieces && hasPrice) {
					setUniquePieces(uniquePieces);
					setUniqueSizes(uniqueSizes);
					setHideSize(true);
				}

				setLoding(false);
			} else if (res?.data?.result === false) {
				setLoding(false);
			}
		} catch (error) {
			setLoding(false);
		}
	}, [id, setDetail, setImg, setLoding]);

	useEffect(() => {
		getData();
	}, [getData]);

	const cartManagement = (updatedDetail, arrayOfProduct) => {
		if(updatedDetail.price==="" || !updatedDetail.price){ return; }
		
		const existingProductIndex = arrayOfProduct.findIndex(
			(item) =>
				item._id === updatedDetail._id &&
				item.size === updatedDetail.size &&
				item.pieces === updatedDetail.pieces
		);

		if (existingProductIndex !== -1) {
			arrayOfProduct[existingProductIndex].quantity += updatedDetail.quantity;
		} else {
			arrayOfProduct.push(updatedDetail);
		}
	};

	const saveData = () => {
		const updatedDetail = { ...detail };
		delete updatedDetail["variant"];

		if (pieces) {
			updatedDetail.pieces = pieces;
			updatedDetail.variant = true;
		}
		if (size) {
			updatedDetail.size = size;
			updatedDetail.variant = true;
		}
		updatedDetail.price = price;
		updatedDetail.quantity = quantity;
		let arrayOfProduct = JSON.parse(localStorage.getItem("myProduct")) || [];
		cartManagement(updatedDetail, arrayOfProduct)
		dispatch(addItem(arrayOfProduct.length));
		const productJSON = JSON.stringify(arrayOfProduct);

		localStorage.setItem("myProduct", productJSON);
		const storedValue = localStorage.getItem("user_id");
		if (!storedValue) {
			setTimeout(() => {
				toast.error("Please Login to Add to Cart", { theme: "colored" });
			}, 100);
			navigate("/login");
			return false;
		}
	};

	const submit = () => {
		const storedValue = localStorage.getItem("user_id");
		if (!storedValue) {
			setTimeout(() => {
				toast.error("Please Login to Order Your product", { theme: "colored" });
			}, 100);
			navigate("/login");
			return false;
		}
		saveData();
		navigate("/cart");
	};

	return (
		<>
			<ScrollToTop
				smooth
				style={{ background: "#2b1b1b", borderRadius: "0px" }}
				component={<IoMdArrowRoundUp className="text-white" />}
			/>
			<Breadcrumb page="Product Detail" />
			<section className="product-details-page6 pro-style6">
				<Container>
					{loding && <Loader />}

					<Row>
						<Col>
							<div className="pro_details_pos pro_details_left_pos">
								<div className="product_detail_slider product_details_tb product_details product_details_sticky">
									<div className="product_detail_img product_detail_img_bottom">
										<div className="product_img_top text-center ">
											<button
												className="text-end cover-img "
												onClick={() => {
													setImg(detail.coverImg);
												}}
											>
											</button>
											{detail.coverImg && (
												<ImageZoom
													className="border"
													src={`${BASE_URL}product/big-img/${img}`}
													alt="Zoom-images"
													zoom="300"
												/>
											)}
											{allImages && allImages?.length > 1 && (
												<ProImgSlide images={allImages} setImg={setImg} />
											)}
										</div>
									</div>
								</div>

								<div className="product_details_wrap product_details_tb product_details">
									<div className="product_details_info">
										<div className="pro-nprist">
											<div className="product-info">
												<div className="product-title">
													{detail?.productName && (
														<h2 className="text-capitalize">
															{detail.productName}
														</h2>
													)}
												</div>
											</div>
											<div className="product-info">
												<div className="pro-prlb pro-sale">
													<div className="price-box">
														{price && (<span className="new-price">₹{price}</span>)}
														{pieces && (<span className="new-price ms-2">({pieces})</span>)}
														{size && (<span className="new-price ms-2">({size})</span>)}
													</div>
												</div>
											</div>

											<div className="product-info">
												<div className="pro-detail-action">
													<Form
														className="cart"
														onSubmit={handleSubmit(saveData)}
													>
														<div className="my-4">
															<div className="variant-wrap ">
																{detail?.variant?.some(obj => obj.pieces !== "") && (
																	<>
																		<div>
																			<h6>
																				<span>Piece:</span>
																				<span className=""></span>
																			</h6>
																		</div>
																		<div className="variant-property my-2 mx-1">
																			<Dropdown
																				onSelect={handlePieceOptionSelect}
																				style={{
																					border: "1px solid #291006",
																					borderRadius: "5px",
																					width: "auto",
																				}}
																			>
																				<Dropdown.Toggle variant="light" id="dropdown-basic">
																					{pieces || "Select"}
																				</Dropdown.Toggle>
																				<Dropdown.Menu>
																					<Dropdown.Item key="select" disabled>
																						Select
																					</Dropdown.Item>
																					{uniquePieces?.map((option, index) => (
																						<Dropdown.Item key={option} eventKey={option}>
																							{option}
																						</Dropdown.Item>
																					))}
																				</Dropdown.Menu>
																			</Dropdown>

																		</div>
																	</>
																)}
																{(hideSize === false && detail?.variant?.some(obj => obj.size !== "")) && (
																	<>
																		<div>
																			<h6>
																				<span>Size:</span>
																				<span className=""></span>
																			</h6>
																		</div>
																		<div className="variant-property my-2 mx-1">
																			<Dropdown
																				onSelect={handleSizeOptionSelect}
																				style={{
																					border: "1px solid #291006",
																					borderRadius: "5px",
																					width: "auto",
																				}}
																			>
																				<Dropdown.Toggle
																					variant="light"
																					id="dropdown-basic"
																				>
																					{size || "Select"}
																				</Dropdown.Toggle>

																				<Dropdown.Menu>
																					{uniqueSizes?.map(
																						(option, index) => (
																							<Dropdown.Item
																								key={option}
																								eventKey={option}
																								selected={index === 0}
																							>
																								{option}
																							</Dropdown.Item>
																						)
																					)}
																				</Dropdown.Menu>
																			</Dropdown>
																		</div>
																	</>
																)}

															</div>
														</div>
													</Form>
												</div>
											</div>
											<div className="product-info">
												<div className="pro-detail-button">
													<div className="product-quantity-button d-flex">
														<div className="product-quantity-action ">
															<h6>Quantity:</h6>
															<IncDec count={quantity} setCount={setQuantity} />
														</div>
														<button
															type="submit"
															className="btn add-to-cart ajax-spin-cart "
															onClick={saveData}
														>
															<span className="cart-title">Add to cart</span>
														</button>
													</div>
													<button className="btn-buy " onClick={submit}>
														Buy now
													</button>
												</div>
											</div>
										</div>
									</div>
									{detail?.description && (
										<div className="product-description-info">
											<h6 className="desc-title">
												<span>Description</span>
											</h6>
											<div className="product-description">
												<p>{detail.description}</p>
											</div>
										</div>
									)}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
}

export default ProductDetail;