import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, Form } from "react-bootstrap";
import Product from "../Product";
import axios from "axios";
import Loader from "../Loader";
import PageTitle from "../../Components/PageTitle";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function AllProduct() {
  const [page, setPage] = useState({});
  const [allcategory, setAllcategory] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState("All");
  const [loding, setLoding] = useState(false);
  const [originalData, setOriginalData] = useState("");

  const [range, setRange] = useState([30, 500]);
  useEffect(() => {
    getData();

    const slug = window.location.pathname.slice(1);

    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => {});
  }, []);

  const getData = async () => {
    setLoding(true);
    try {
      const [productResponse, categoryResponse] = await Promise.all([
        axios.get(BASE_URL + "product"),
        axios.get(BASE_URL + "category"),
      ]);

      if (productResponse?.data?.result === true) {
        setRange([productResponse.data.minPrice<=5?0:productResponse.data.minPrice-5,productResponse.data.maxPrice+10])
        setOriginalData(productResponse.data.data);
        setCategoryData(productResponse.data.data);

        setLoding(false);
      } else if (productResponse?.data?.result === false) {
        setLoding(false);
      }

      if (categoryResponse?.data?.result === true) {
        const new_all = categoryResponse.data.data.map((cat) => ({
          ...cat,
          prodCount: productResponse.data.data.reduce((total, pro) => {
            if (cat.categoryName === pro.categoryName) {
              return 1 + total;
            }
            return total;
          }, 0),
        }));

        new_all.unshift({ categoryName: "All", _id: "111" });
        setAllcategory(new_all);
        setLoding(false);
      } else if (categoryResponse?.data?.result === false) {
        setLoding(false);
      }
    } catch (error) {
      setLoding(false);
    }
    axios
      .get(BASE_URL + "product")
      .then((res) => {
        if (res?.data?.result === true) {
          setOriginalData(res.data.data);
          setCategoryData(res.data.data);

          setLoding(false);
        } else if (res?.data?.result === false) {
          setLoding(false);
        }
      })
      .catch(() => {
        setLoding(false);
      });
  };

  const handleFilterOpt = (cate) => {
    setCategory(cate);

    const d =
      cate === "All"
        ? originalData
        : originalData.filter((item) => item.categoryName === cate);
    setCategoryData(
      d.filter(
        (item) =>
          item.variant[0].price >= range[0] && item.variant[0].price <= range[1]
      )
    );
  };

  return (
    <>
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <section className="product-features product-category pb-5">
        <Container>
          {loding && <Loader />}
          <PageTitle pageData={page} />
          <Row>
            <Row className="border-bottom">
              <Col lg={3}>
                <div className="d-flex ms-3 mb-1">
                  <Form.Label className="mt-2 me-1 fw-bold text-muted category-title">
                    Category:
                  </Form.Label>
                  <Dropdown
                    onSelect={handleFilterOpt}
                    style={{
                      width: "auto",
                    }}
                    className="mt-2 ms-2 drop-down"
                  >
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      {category || "Select"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {allcategory?.map((option, index) => (
                        <Dropdown.Item
                          key={option._id}
                          eventKey={option.categoryName}
                        >
                          {option.categoryName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>

            {categoryData?.map((item) => (
              <Col xs={12} sm={6} md={4} lg={3} key={item._id}>
                <Product product={item} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default AllProduct;
