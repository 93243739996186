import React, { useState, useEffect } from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const WhatsAppButton = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get(BASE_URL + 'footer').then((response) => {
      if (response?.data?.result === true) {
        setData(response?.data?.data);
      }
    });
  }, []);

  const openWhatsApp = () => {
    window.open(data.whatsappUrl, '_blank');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      openWhatsApp();
    }
  };

  return (
    <button
      className="whatsapp-button"
      onClick={openWhatsApp}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      aria-label="Open WhatsApp"
    >
      <IoLogoWhatsapp size={30} />
    </button>
  );
};

export default WhatsAppButton;
