import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Loader from "../Loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import PageTitle from "../PageTitle";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Address() {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [loding, setLoding] = useState(false);
  const [page, setPage] = useState({});
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState([]);
  const [err, setErr] = useState();

  const id = localStorage.getItem("user_id");
  useEffect(() => {
    if (id) {
      axios
        .get(BASE_URL + `customer/${id}`)
        .then((res) => {
          if (res?.data?.result === true) {
            const selectedValue = stateData?.find(
              (item) => item.state === res.data.data.state
            );
            setCityData(selectedValue.city);
            setValue("doorNo", res.data.data.doorNo);
            setValue("state", res.data.data.state);
            setValue("address", res.data.data.address);
            setValue("city", res.data.data.city);
            setValue("landmark", res.data.data.landmark);
            setValue("zipCode", res.data.data.zipCode);
            setLoding(false);
          } else if (res?.data?.result === false) {
            setLoding(false);
          }
        })
        .catch(() => {
          setLoding(false);
        });
    }

    const path = window.location.pathname.slice(1);
    const parts = path.split("/");
    const slug = parts[1];

    axios
      .get(BASE_URL + `seo/find-slug/${slug}`)
      .then((res) => {
        if (res?.data?.result === true) {
          setPage(res.data.data);
        }
      })
      .catch(() => {});
  }, [id, setValue, stateData]);
  useEffect(() => {
    setLoding(true);
    axios
      .get(BASE_URL + `city`)
      .then((res) => {
        if (res?.data?.result === true) {
          setStateData(res.data.data);
          setLoding(false);
        }
      })
      .catch(() => {});
  }, []);

  const handleStateChange = (e, field) => {
    let selectedValue = e.target.value;
    field.onChange(selectedValue);
    selectedValue = stateData?.find((item) => item.state === selectedValue);
    setCityData(selectedValue.city);
  };
  const submit = (data) => {
    setLoding(true);
    setErr("");
    axios
      .put(BASE_URL + `customer/address/${id}`, data)
      .then((res) => {
        if (res?.data?.result === true) {
          setTimeout(() => {
            toast.success(res.data.message, { theme: "colored" });
          }, 100);
          setLoding(false);
        } else if (res?.data?.result === false) {
          setErr(res.data.errors);
          setLoding(false);
        }
      })
      .catch(() => {
        setLoding(false);
      });
  };

  return (
    <Container className="">
      {loding && <Loader />}
      <PageTitle pageData={page} />

      <Row>
        <Col lg={12}>
          <div className="profile-border">Address</div>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(submit)}>
        <div className="login-form-container">
          <Row>
            <Col lg={6}>
              <Row>
                <Col>
                  <Form.Group
                    controlId="formApartment"
                    className="log-email mt-3"
                  >
                    <Form.Label>Apartment, Door no, unit etc.</Form.Label>
                    <Form.Control
                      type="text"
                      style={{
                        border: "1px solid rgba(130, 88, 70, 0.8)",
                        borderRadius: "0px",
                      }}
                      placeholder="Enter Door no, unit etc"
                      {...register("doorNo", {
                        required: "This field is required.",
                      })}
                    />
                    {errors.doorNo && (
                      <span className="text-danger">
                        {errors.doorNo.message}
                      </span>
                    )}
                    {err?.doorNo && (
                      <span className="text-danger">{err?.doorNo}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="log-email mt-3">
                    <Form.Label>Landmark</Form.Label>
                    <Form.Control
                      style={{
                        border: "1px solid rgba(130, 88, 70, 0.8)",
                        borderRadius: "0px",
                      }}
                      type="text"
                      {...register("landmark", {
                        required: "Landmark is required",
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message:
                            "Landmark must only contain alphabetic characters",
                        },
                      })}
                      placeholder="Enter Your Landmark"
                      name="landmark"
                      isInvalid={errors?.landmark}
                    ></Form.Control>
                    {errors?.landmark && (
                      <span className="text-danger">
                        {errors?.landmark?.message}
                      </span>
                    )}
                    {err?.landmark && (
                      <span className="text-danger">{err?.landmark}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  {/* <Form.Group className="log-email mt-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      style={{
                        border: "1px solid rgba(130, 88, 70, 0.8)",
                        borderRadius: "0px",
                      }}
                      type="text"
                      {...register("state", {
                        required: "State is required",
                        pattern: {
                          value: /^[a-zA-Z\s]+$/,
                          message:
                            "State must only contain alphabetic characters",
                        },
                      })}
                      placeholder="Enter Your State"
                      name="state"
                      isInvalid={errors?.state}
                    ></Form.Control>
                    {errors?.state && (
                      <span className="text-danger">
                        {errors?.state?.message}
                      </span>
                    )}
                    {err?.state && (
                      <span className="text-danger">{err?.state}</span>
                    )}
                  </Form.Group> */}
                  <Form.Group className="mb-3 ">
                    <Form.Label>State</Form.Label>
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: "State is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          onChange={(e) => handleStateChange(e, field)}
                          style={{
                            border: "1px solid rgba(130, 88, 70, 0.8)",
                            borderRadius: "0px",
                          }}
                        >
                          <option value="" disabled>
                            Select an option
                          </option>
                          {stateData?.map((item) => (
                            <option key={item._id} value={item.state}>
                              {item.state}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    />

                    {errors.state && (
                      <span className="text-danger">
                        {errors?.state?.message}
                      </span>
                    )}

                    {err?.state && (
                      <span className="text-danger">{err?.state}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="form-action-button">
              <Form.Group className="log-email mt-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid rgba(130, 88, 70, 0.8)",
                    borderRadius: "0px",
                  }}
                  type="text"
                  {...register("address", {
                    required: "Address is required",
                    pattern: {
                      value: /^[a-zA-Z\s,.'-]+$/,
                      message:
                        "Address must only contain alphabetic characters ,coma and dot",
                    },
                  })}
                  placeholder="Enter Your Address"
                  name="address"
                  isInvalid={errors?.address}
                ></Form.Control>
                {errors?.address && (
                  <span className="text-danger">
                    {errors?.address?.message}
                  </span>
                )}
                {err?.address && (
                  <span className="text-danger">{err?.address}</span>
                )}
              </Form.Group>
              <Form.Group controlId="formApartment" className="log-email mt-3">
                <Form.Label>Zip/Post Code</Form.Label>
                <Form.Control
                  type="text"
                  style={{
                    border: "1px solid rgba(130, 88, 70, 0.8)",
                    borderRadius: "0px",
                  }}
                  placeholder="Enter numeric value"
                  {...register("zipCode", {
                    required: "This field is required.",
                    pattern: {
                      value: /^\d+$/,
                      message: "Please enter only numeric characters.",
                    },
                  })}
                />
                {errors.zipCode && (
                  <span className="text-danger">{errors.zipCode.message}</span>
                )}
                {err?.zipCode && (
                  <span className="text-danger">{err?.zipCode}</span>
                )}
              </Form.Group>
              {/* <Form.Group className="log-email mt-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  style={{
                    border: "1px solid rgba(130, 88, 70, 0.8)",
                    borderRadius: "0px",
                  }}
                  type="text"
                  {...register("city", {
                    required: "City is required",
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "City must only contain alphabetic characters",
                    },
                  })}
                  placeholder="Enter Your City"
                  name="city"
                  isInvalid={errors?.city}
                ></Form.Control>
                {errors?.city && (
                  <span className="text-danger">
                    {errors?.city?.message}
                  </span>
                )}
                {err?.city && <span className="text-danger">{err?.city}</span>}
              </Form.Group> */}
              <Form.Group className="log-email mt-3">
                <Form.Label>Town / City</Form.Label>
                <Controller
                  name="city"
                  control={control}
                  style={{
                    border: "1px solid rgba(130, 88, 70, 0.8)",
                    borderRadius: "0px",
                  }}
                  rules={{
                    validate: (value) => {
                      if (value && value !== "Select an option") {
                        return null;
                      } else {
                        return "City is required";
                      }
                    },
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Select
                      {...field}
                      style={{
                        border: "1px solid rgba(130, 88, 70, 0.8)",
                        borderRadius: "0px",
                      }}
                    >
                      <option value={null}>Select an option</option>
                      {cityData?.map((item, index) => (
                        <option key={`ind${item}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
                {errors?.city && (
                  <span className="text-danger">{errors?.city?.message}</span>
                )}
                {err?.city && <span className="text-danger">{err?.city}</span>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-action-button mt-4">
                <Button className="account-btn fs-5 w-auto mb-4 " type="submit">
                  Update Address
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      <ToastContainer />
    </Container>
  );
}

export default Address;
