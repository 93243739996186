import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'; 
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function Product({ product }) {
  const { _id, images, coverImg, variant, productName } = product;
  return (
    <div className="single-product-wrap">
      <div className="product-image">
        <Link to={`/product-detail/${_id}`} className="pro-img">
          <Image
            src={`${BASE_URL}product/uploads/${coverImg}`}
            className="img-fluid img1"
            alt={`p-${productName}`}
          />
          {images && images.length > 0 && (
            <Image
              src={`${BASE_URL}product/uploads/${images[0]}`}
              className="img-fluid img2"
              alt={`p-hover-${productName}`}
            />
          )}
          {images && images.length === 0 && (
            <Image
              src={`${BASE_URL}product/uploads/${coverImg}`}
              className="img-fluid img2"
              alt={`p-hover-${productName}`}
            />
          )}
        </Link>
      </div>

      <div className="product-content">
        <h6>
          <Link
            to={`/product-detail/${_id}`}
            className="text-decoration-none text-capitalize"
          >
            {productName}
          </Link>
        </h6>

        <div className="price-box">
          <span className="new-price">{`₹ ${variant[0].price}`}</span>
        </div>
      </div>
    </div>
  );
}

Product.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    images: PropTypes.array,
    coverImg: PropTypes.string.isRequired,
    variant: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.string.isRequired,
       
      })
    ),
    productName: PropTypes.string.isRequired,
  }).isRequired,
};

export default Product;
