import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Loader from "../Loader";
import Product from "../Product";
import axios from "axios";
import PropTypes from 'prop-types';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function FeatureProduct({ detail }) {
  const [data, setData] = useState([]);
  const [loding, setLoding] = useState(false);

  const getData = async () => {
    setLoding(true);
    await axios
      .get(BASE_URL + "product")
      .then((res) => {
        if (res?.data?.result === true) {
          setData(res.data.data.filter((item) => item.displayHome === true));

          setLoding(false);
        } else if (res?.data?.result === false) {
          setLoding(false);
        }
      })
      .catch(() => {
        setLoding(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {data?.length !== 0 && (
        <section className="product-features pt-5">
          {loding && <Loader />}

          <Container>
            <Row>
              <div className="section-title mb-2 ">
                {/* <span className="sub-title">BREADS EVERY DAY</span> */}
                <h2 className="our-products">
                  <span>Our </span> Products
                </h2>
              </div>
              <div className="our-products-quote">
                <p>{detail?.ourProductQuote}</p>
              </div>
              <Col xs={12} className="align-items-center">
                <Row className="d-flex pb-5">
                  {data?.map((item, index) => (
                    <Col md={4} key={item._id}>
                      <div className="swiper-slide">
                        <Product product={item} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
}

FeatureProduct.propTypes = {
  data: PropTypes.shape({
    ourProductQuote: PropTypes.string,
    detail: PropTypes.string,
  }),
};
export default FeatureProduct;
