import React,{useState} from 'react'
import Breadcrumb from '../Components/Breadcrumb'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "../Components/Loader";
import { useNavigate,useParams,Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function ResetPassword() {
  const [loding, setLoding] = useState(false);
    const [err, setErr] = useState();
  const navigate = useNavigate();
  const {encemail}=useParams();
    const {
      register,
      handleSubmit,
      getValues,
      formState: { errors }
    } = useForm({ mode: "all" });
    const forgotPass=(data)=>{
      
      setLoding(true)
      setErr("")
      data["encemail"]=encemail;
      axios.put(BASE_URL+`customer/change-password`,data)
      .then((res)=>{
       if (res?.data?.result === true) {
         setLoding(false)
         navigate('/login')
        }
       else if(res?.data?.result === false){
        setErr(res.data.errors);
         setLoding(false)
       }
      })
      .catch(()=>{
       setLoding(false)
     })
    }
  return (
    <>
    <Breadcrumb page="Login"/>
    <section className="customer-page section-ptb">
     <Container>
     {loding && <Loader />}

       <Row>
         <Col>
           <div className="acc-form">
             <div className="log-acc-page" id="CustomerLoginForm">
               {/* account title start */}
               <div className="content-main-title">
                 <div className="section-cont-title text-center">
                   <h2>
                     <span>Welcome Back !</span>
                   </h2>
                   <p>Reset Your Password.</p>
                 </div>
               </div>
               {/* account title end */}
               {/* account login start */}
               <div className="acc-page">
                 <div className="login">
                   <Form onSubmit={handleSubmit(forgotPass)}>
                     <div className="login-form-container">
                     <Form.Group className="log-email mt-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              style={{
                                border: "1px solid rgba(130, 88, 70, 0.8)",
                                borderRadius: "0px",
                              }}
                              type="password"
                              placeholder="Enter Password"
                              {...register("password", {
                                required: "Password is Required",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Password must be minimum 8 characters",
                                },
                                validate: (value) => {
                                  const regex =
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;
                                  
                                    if(!regex.test(value)){
                                      return ("Password must contain at least one uppercase, symbol, and number");
                                    }
                                  
                                },
                              })}
                              isInvalid={errors?.password}
                            />
                            {errors?.password?.message && (
                              <span className="text-danger">
                                {errors.password.message}
                              </span>
                            )}
                            {err?.password && (
                              <span className="text-danger">
                                {err?.password}
                              </span>
                            )}
                          </Form.Group>

                          <Form.Group className="log-email mt-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              style={{
                                border: "1px solid rgba(130, 88, 70, 0.8)",
                                borderRadius: "0px",
                              }}
                              type="password"
                              placeholder="Confirm Password"
                              {...register("confirmPassword", {
                                required: "Confirm Password is Required",
                                validate: (value) =>
                                  value === getValues("password") ||
                                  "Passwords do not match",
                              })}
                              isInvalid={errors?.confirmPassword}
                            />
                            {errors?.confirmPassword && (
                              <span className="text-danger">
                                {errors.confirmPassword.message}
                              </span>
                            )}
                          </Form.Group>

                     

                       <div className="form-action-button mb-5">
                         <Button className="account-forgot-btn fs-5 text-uppercase "   type="submit">
                           Submit
                         </Button>
                         <Link to="/login"  className='text-muted w-auto fs-5 pb-2 border-0' >
                            Cancel
                          </Link>
                       </div>
                     </div>
                   </Form>
                 </div>
                 
               </div>
               {/* account login end */}
             </div>

            
           </div>
         </Col>
       </Row>
     </Container>
   </section>
   </>
  )
}


export default ResetPassword