import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Breadcrumb from "../Components/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoPlus } from "react-icons/go";
import { HiMinusSmall } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { addItem } from "../slice/ProductCountSlice";
import PageTitle from "../Components/PageTitle";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowRoundUp } from "react-icons/io";
import { toast,ToastContainer } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

function MyCart() {
  const [data, setData] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState({});

  const removeItem = (index) => {
    let newData;
    setData((prevData) => {
      newData = prevData.filter((item, i) => i !== index);
      const productJSON = JSON.stringify(newData);
      localStorage.setItem("myProduct", productJSON);
      getData();
      return newData;
    });
    dispatch(addItem(newData?.length));
  };

  const getTotal = (product_data) => {
    let alldata = product_data;
    const total = alldata.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.quantity * currentItem.price;
    }, 0);
    setGrandTotal(total);
    setTotalItem(alldata.length);
  };

  const getData = useCallback(() => {
    const storedProductJSON = localStorage.getItem("myProduct");
    if (storedProductJSON) {
      const storedProduct = JSON.parse(storedProductJSON);
      setData(storedProduct);
      getTotal(storedProduct);
    }
  }, []);
  const handleIncrementCounter = (e, index) => {
    e.preventDefault();
    let alldata = [...data];
    const product = alldata?.find((item, i) => i === index);
    if (product.quantity < 100) {
      product.quantity += 1;
      alldata.splice(index, 1, product);

      setData(alldata);

      localStorage.setItem("myProduct", JSON.stringify(alldata));
      getData();
    }
  };

  const handleDecrementCounter = (e, index) => {
    e.preventDefault();

    let alldata = [...data];
    const product = alldata?.find((item, i) => i === index);
    if (product.quantity > 1) {
      product.quantity -= 1;
      alldata.splice(index, 1, product);

      setData(alldata);

      localStorage.setItem("myProduct", JSON.stringify(alldata));
      getData();
    }
  };
  useEffect(() => {
    getData();
    const storedValue = localStorage.getItem("user_id");
    if (!storedValue) {
      setTimeout(() => {
        toast.error("Please Login to see Your Cart", { theme: "colored" });
      }, 100);
      navigate("/login");
    }
    const slug = window.location.pathname.slice(1);
    axios.get(BASE_URL + `seo/find-slug/${slug}`).then((res) => {
      if (res?.data?.result === true) {
        setPage(res.data.data);
      }
    });
  }, [navigate, getData]);
  const checkProduct=()=>{
    const storedProduct = JSON.parse(localStorage.getItem("myProduct")) || [];
    if (storedProduct.length < 1) {
      setTimeout(() => {
        toast.error("Please Add Product To Checkout", { theme: "colored" });
      }, 100);
    }
    else{
      navigate("/checkout")
    }
  }
  return (
    <>
      <ScrollToTop
        smooth
        style={{ background: "#2b1b1b", borderRadius: "0px" }}
        component={<IoMdArrowRoundUp className="text-white" />}
      />
      <PageTitle pageData={page} />
      <Breadcrumb page="Cart" />
      <section className="cart-page section-ptb mb-5 pb-3 pt-3 mt-3">
        <Container>
          <Row>
            <Col>
              <div className="cart-page-wrap">
                <div className="cart-wrap-info">
                  <div className="cart-item-wrap mt-5">
                    <div className="cart-title">
                      <h6>My cart:</h6>
                      <span className="cart-count">
                        <span className="cart-counter">{totalItem}</span>
                        <span className="cart-item-title">Item</span>
                      </span>
                    </div>
                    <div className="item-wrap">
                      {/* Cart items go here */}

                      {data?.map((item, index) => (
                        <ul className="cart-wrap" key={item.price + item._id}>
                          <li className="item-info mb-4">
                            <div className="item-img">
                              <Link to={`/product-detail/${item._id}`}>
                                <img
                                  src={`${BASE_URL}product/uploads/${item.coverImg}`}
                                  className="img-fluid"
                                  alt="p-1"
                                />
                              </Link>
                            </div>
                            <div className="item-title">
                              <Link
                                to={`/product-detail/${item._id}`}
                                className="text-decoration-none"
                              >
                                {item.productName}
                              </Link>
                              {item.size && (
                                <span className="item-option">
                                  <span className="pro-variant-title">
                                    Size:
                                  </span>
                                  <span className="pro-variant-type">
                                    {item.size}
                                  </span>
                                </span>
                              )}
                              {item.pieces && (
                                <span className="item-option">
                                  <span className="pro-variant-title">
                                    Pieces:
                                  </span>
                                  <span className="pro-variant-type">
                                    {item.pieces}
                                  </span>
                                </span>
                              )}

                              <span className="item-option">
                                <span className="item-price">
                                  ₹{item.price}
                                </span>
                              </span>
                            </div>
                            <div className="me-auto "></div>
                          </li>
                          <li className="item-qty">
                            <div className="product-quantity-action">
                              <div className="product-quantity">
                                <div className="cart-plus-minus">
                                  <div className="b-group ">
                                    <button
                                      className=" i-btn decrement-btn"
                                      style={{
                                        borderRight: "1px solid #825846",
                                      }}
                                      onClick={(e) => {
                                        handleDecrementCounter(e, index);
                                      }}
                                    >
                                      <span className="material-symbols-outlined">
                                        <HiMinusSmall />
                                      </span>
                                    </button>
                                    <FormControl
                                      type="text"
                                      name="quantity"
                                      className="count text-center w-50"
                                      value={item.quantity}
                                      readOnly
                                    />
                                    <button
                                      className="i-btn increment-btn"
                                      style={{
                                        borderLeft: "1px solid #825846",
                                      }}
                                      onClick={(e) => {
                                        handleIncrementCounter(e, index);
                                      }}
                                    >
                                      <span className="material-symbols-outlined">
                                        <GoPlus />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="item-remove">
                              <span className="remove-wrap">
                                <Link
                                  href="#"
                                  className="text-danger text-decoration-none"
                                  onClick={() => {
                                    removeItem(index);
                                  }}
                                >
                                  Remove
                                </Link>
                              </span>
                            </div>
                          </li>

                          <li className="item-price">
                            <span className="amount full-price">
                              ₹{item.price * item.quantity}
                            </span>
                          </li>
                        </ul>
                      ))}
                    </div>
                    <div className="">
                      <Link
                        to="/products"
                        className="btn-style2  mycart-buttons"
                      >
                        Continue shopping
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="cart-info-wrap mt-5 pt-4">
                  <div className="cart-total-wrap cart-info">
                    <div className="cart-total">
                      <div className="total-amount">
                        <h6 className="total-title">Total</h6>
                        <span className="amount total-price">{grandTotal}</span>
                      </div>
                      <div className="proceed-to-checkout">
                        <button
                          onClick={checkProduct}
                          className="py-3 px-5 fw-bold text-decoration-none"

                        >
                          Checkout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer/>
      </section>
    </>
  );
}

export default MyCart;
